import { useState, useEffect, useRef } from 'react'
import { DateTime, Duration, Interval } from 'luxon'
import { useQuery, gql } from '@apollo/client'

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	List,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { ListItem, DeleteButton } from 'components'

export default function PomodoroPage() {
	const [tasks, setTasks] = useState<any[]>([])
	const [isSelectTasksDialogOpen, setIsSelectTasksDialogOpen] = useState(false)

	function removeTask(taskId: string) {
		setTasks(tasks.filter(task => task.id !== taskId))
	}

	return (
		<>
			<Box textAlign={'center'} alignContent="center">
				<Timer minutes={25} />
				<div>list of tasks</div>
				<Button onClick={() => setIsSelectTasksDialogOpen(true)}>Add Task</Button>
				<List>
					{tasks.map(task => (
						<ListItem
							key={task.id}
							primary={task.name}
							secondaryAction={<DeleteButton onClick={() => removeTask(task.id)} />}
						/>
					))}
				</List>
			</Box>
			<SelectTasksDialog
				open={isSelectTasksDialogOpen}
				onClose={() => setIsSelectTasksDialogOpen(false)}
			/>
		</>
	)
}

function TimerSettings({ minutes, setMinutes }) {
	return (
		<div>
			<TextField variant="standard" value={minutes} onChange={e => console.log(e.target.value)} />
		</div>
	)
}

function Timer({ minutes }) {
	const [duration, setDuration] = useState<Duration>(Duration.fromObject({ minutes }))

	const [startTime, setStartTime] = useState<DateTime | null>(null)
	const [started, setStarted] = useState(false)
	const [timeLeft, setTimeLeft] = useState<Duration>(duration)

	const hasBeenStarted = !!startTime
	const hasTimeLeft = timeLeft.toMillis() > 0

	function startStop() {
		setStarted(started => !started)
		setStartTime(DateTime.now())
		setDuration(timeLeft)
	}

	function reset() {
		setStarted(false)
		setTimeLeft(Duration.fromObject({ minutes }))
		setStartTime(null)
	}

	useEffect(() => {
		let timer: NodeJS.Timeout
		if (started) {
			if (hasTimeLeft) {
				timer = setInterval(() => {
					setTimeLeft(duration.minus(DateTime.now().minus(startTime!.toMillis()).toMillis()))
				}, 0)
			} else {
				setStarted(false)
				setTimeLeft(Duration.fromMillis(0))
			}
		}

		return () => clearInterval(timer)
	}, [duration, started, startTime, setStarted, setTimeLeft, hasTimeLeft])

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }}>
				<Typography variant="h1">{timeLeft.toFormat('mm:ss')}</Typography>
				{/* <Typography variant="h2">
					.{DateTime.fromMillis(timeLeft.toMillis()).toFormat('uu')}
				</Typography> */}
			</Box>
			<Button onClick={startStop} disabled={!hasTimeLeft}>
				{started ? 'Pause' : hasBeenStarted ? 'resume' : 'Start'}
			</Button>
			<Button onClick={reset}>reset</Button>
		</>
	)
}

function SelectTasksDialog({ open, onClose }) {
	const { data: { lists = [] } = {}, loading, error } = useQuery(GET_LISTS)

	const [selectedList, setSlectedList] = useState<any>()

	return (
		<Dialog PaperProps={{ sx: { height: 0.5 } }} open={open} onClose={onClose}>
			<DialogTitle>Selects Tasks</DialogTitle>
			<DialogContent>
				<Stack
					direction="row"
					spacing={1}
					divider={<Divider orientation="vertical" flexItem />}
					sx={{ height: 1 }}
				>
					<List sx={{ width: 200 }}>
						{lists.map(list => (
							<ListItem key={list.id} primary={list.name} onClick={() => setSlectedList(list)} />
						))}
					</List>
					<List sx={{ width: 400 }}>
						{selectedList?.listItems.map(({ item }) => (
							<ListItem key={item.id} primary={item.name} />
						))}
					</List>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button color="success" onClick={onClose}>
					Done
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const GET_LISTS = gql`
	query Lists {
		lists(orderBy: { id: ASC }) {
			id
			name
			description
			createdAt
			updatedAt
			listItems {
				item {
					id
					name
					completedAt
					dueBy
					createdAt
				}
			}
		}
	}
`
