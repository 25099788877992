import { useMutation, gql } from '@apollo/client'

export default function useDeleteActivity() {
	function deleteActivity(id) {
		deleteActivityMutation({
			variables: { id },
		})
	}

	const [deleteActivityMutation, deleteActivityStatus] = useMutation(
		gql`
			mutation DeleteActivityByPk($id: Int!) {
				deleteActivityByPk(id: $id) {
					id
				}
			}
		`,
		{
			update(
				cache,
				{
					data: {
						deleteActivityByPk: { id },
					},
				}
			) {
				cache.evict({ id: `Activity:${id}` })
			},
		}
	)

	return {
		deleteActivity,
		deleteActivityStatus,
	}
}
