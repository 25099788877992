import { IconButton } from '@mui/material'
import {
	ArrowDropDownRounded as ArrowDownIcon,
	ArrowDropUpRounded as ArrowUpIcon,
	ArrowLeftRounded as ArrowLeftIcon,
	ArrowRightRounded as ArrowRightIcon,
} from '@mui/icons-material'

export default function ShowMoreLessButton({
	open,
	onClick,
	size = 'large',
	orientation = 'vertical',
}: {
	open: boolean
	onClick: () => void
	size?: 'small' | 'medium' | 'large'
	orientation?: 'vertical' | 'horizontal'
}) {
	const OpenedIcon = orientation === 'vertical' ? ArrowUpIcon : ArrowDownIcon
	const ClosedIcon = orientation === 'vertical' ? ArrowDownIcon : ArrowRightIcon

	return (
		<IconButton size={size} onClick={onClick}>
			{open ? <OpenedIcon /> : <ClosedIcon />}
		</IconButton>
	)
}
