import { createTheme, responsiveFontSizes } from '@mui/material'
import { teal, orange } from '@mui/material/colors'

const theme = mode => {
	let theme = createTheme({
		palette: {
			mode,
			primary: {
				main: '#FFFFFF',
			},
			secondary: orange,
			background: {
				default: 'black',
			},
		},
		typography: {
			fontSize: 16,
			fontFamily: 'helvetica',
			link: {
				color: 'light gray',
			},
			h1: {
				fontSize: 256,
			},
			// h4: {
			// 	fontWeight: '',
			// },
			button: {
				textTransform: 'none',
			},
			dayView: {
				fontSize: 12,
			},
		},
		spacing: 4,
	})

	theme = responsiveFontSizes(
		createTheme(theme, {
			components: {
				MuiCssBaseline: {
					styleOverrides: {
						body: {
							transition: 'all 0.3s linear',
							overflowY: 'scroll',
						},
						a: {
							textDecoration: 'none',
							color: 'inherit',
						},
						'::-webkit-scrollbar': {
							backgroundColor: 'inherit', //theme.palette.background.default,
							width: 8,
						},
						'::-webkit-scrollbar-thumb': {
							backgroundColor: theme.palette.grey['800'],
							borderRadius: 8,
						},
						'::-webkit-calendar-picker-indicator': {
							backgroundColor: 'white',
							borderRadius: 8,
						},
						'input[type="color"]': {
							appearance: 'none',
							background: 'none',
							border: 'none',
							cursor: 'pointer',
							padding: 0,
							borderRadius: 100,
						},
						'::-webkit-color-swatch': {
							border: 'none',
						},
					},
				},
				MuiButtonBase: {
					defaultProps: {
						disableRipple: true,
					},
					styleOverrides: {
						root: {
							marginLeft: 0,
							borderRadius: 8,
						},
					},
				},
				MuiButton: {
					styleOverrides: {
						root: {
							borderRadius: 12,
						},
					},
				},
				MuiChip: {
					styleOverrides: {
						root: {
							backgroundColor: '#252525',
						},
					},
				},
				MuiDialog: {
					defaultProps: {
						disableScrollLock: true,
					},
				},
				MuiPaper: {
					styleOverrides: {
						root: {
							borderRadius: 16,
						},
					},
				},
				MuiCardHeader: {
					styleOverrides: {
						root: {
							padding: 8,
						},
						action: {
							marginRight: -2,
						},
					},
				},
				MuiCardContent: {
					styleOverrides: {
						root: {
							padding: 8,
						},
					},
				},
				MuiCheckbox: {
					styleOverrides: {
						root: {
							'&:hover': {
								backgroundColor: theme.palette.action.hover,
							},
						},
					},
				},
				MuiListItem: {
					styleOverrides: {
						root: {
							borderRadius: 16,
							// alignItems: 'start',
							transition: 'all .1s ease-in-out',
							// backgroundColor: orange[400],
							'&:hover': {
								// opacity: 1, //({ theme }) => theme.palette.action.hoverOpacity,
								// transform: 'scale(1.01)',
								backgroundColor: theme.palette.action.hover,
							},
						},
					},
				},
				MuiListItemButton: {
					styleOverrides: {
						root: {
							'&:hover': { backgroundColor: 'transparent' },
						},
					},
				},
				MuiListItemSecondaryAction: {
					styleOverrides: {
						root: {
							right: '8px',
							top: '8px',
							transform: 'none',
						},
					},
				},
				MuiListItemText: {
					styleOverrides: {
						root: {
							margin: 0,
						},
					},
				},
				MuiTextField: {
					defaultProps: {
						size: 'small',
					},
					styleOverrides: {
						root: {
							'&:hover': {
								'& .MuiOutlinedInput-notchedOutline': {
									borderWidth: 1,
								},
							},
							maxWidth: '100%',
						},
						fieldSet: {
							border: 'none',
						},
					},
				},
				MuiMenu: {
					defaultProps: {
						disableScrollLock: true,
					},
					styleOverrides: {
						list: {
							padding: theme.spacing(2, 1),
						},
					},
				},
				MuiMenuItem: {
					defaultProps: {
						dense: true,
					},
					// styleOverrides: {
					// 	root: {
					// 		margin: theme.spacing(1),
					// 	},
					// },
				},
				MuiTypography: {
					defaultProps: {
						variantMapping: {
							dayView: 'p',
						},
					},
				},
			},
		})
	)

	return theme
}

export default theme

declare module '@mui/material/styles' {
	interface TypographyVariants {
		link: React.CSSProperties
		dayView: React.CSSProperties
	}

	interface TypographyVariantsOptions {
		link?: React.CSSProperties
		dayView?: React.CSSProperties
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		link: true
		dayView: true
	}
}
