import { useState, useEffect, useLayoutEffect, useRef } from 'react'

import { Box, InputBase } from '@mui/material'

export default function TextInput({
	value,
	onUpdate,
	onChange,
	minWidth = 50,
	placeholder = '',
	sx,
	multiline = false,
	fullWidth = false,
	clearOnSubmit = false,
	keepFocusOnSubmit = false,
	type = 'text',
	size,
	inputProps,
}: {
	value: string | number
	onUpdate?: (value: string) => void
	onChange?: (value: string) => void
	minWidth?: number
	placeholder?: string
	sx?: object
	multiline?: boolean
	fullWidth?: boolean
	clearOnSubmit?: boolean
	keepFocusOnSubmit?: boolean
	type?: 'text' | 'number' | 'search' | 'datetime-local' | 'color'
	size?: 'small'
	inputProps?: object
}) {
	function handleSubmit(e) {
		if (onUpdate) {
			if (keepFocusOnSubmit) {
				handleUpdate(e.target.value)
			} else {
				e.target.blur()
			}
			if (clearOnSubmit) e.target.value = ''
		}
	}

	function handleUpdate(value) {
		if (onUpdate) {
			onUpdate(value.trim())
		}
	}

	function handleBlur(e) {
		handleUpdate(e.target.value)
	}

	return (
		<Box
			sx={{
				bgcolor: theme => theme.palette.background.paper,
				borderRadius: 3,
				p: theme => theme.spacing(1, 2),
				...sx,
			}}
		>
			<InputBase
				placeholder={placeholder}
				defaultValue={value}
				onBlur={handleBlur}
				onChange={e => {
					if (onChange) onChange(e.target.value)
				}}
				multiline={multiline}
				fullWidth={fullWidth}
				type={type}
				size={size}
				inputProps={inputProps}
			/>
		</Box>
	)
}
