import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'

import {
	AddRounded as AddIcon,
	AppsRounded as ActivitiesIcon,
	CalendarViewDayRounded as DayViewIcon,
	TaskAltRounded as GoalsIcon,
	ListRounded as ListIcon,
	HomeRounded as HomeIcon,
} from '@mui/icons-material'

export const BOTTOM_NAV_HEIGHT_PX = 84

export default function MobileNavBar() {
	const location = useLocation()
	const navigate = useNavigate()

	const [value, setValue] = useState(location.pathname)

	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 3,
				height: BOTTOM_NAV_HEIGHT_PX,
				backdropFilter: 'blur(20px)',
			}}
		>
			<BottomNavigation
				showLabels
				value={value}
				onChange={(event, value) => {
					setValue(value)
					navigate(value)
				}}
				sx={{
					backgroundColor: 'transparent',
				}}
			>
				<BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} />
				<BottomNavigationAction label="Timeline" value="/day-view" icon={<DayViewIcon />} />
				{/* <BottomNavigationAction label="Goals" value="/goals" icon={<GoalsIcon />} /> */}
				<BottomNavigationAction label="Create" value="/create" icon={<AddIcon />} />
				<BottomNavigationAction label="Activities" value="/activities" icon={<ActivitiesIcon />} />
				<BottomNavigationAction label="Lists" value="/lists" icon={<ListIcon />} />
			</BottomNavigation>
		</Box>
	)
}
