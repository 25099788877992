import { useMutation, gql } from '@apollo/client'

import { GET_SUMMARY } from 'pages/HomePage/HomePage'
import { DateTime } from 'luxon'

export default function useGoals() {
	const [createGoalMutation, createGoalStatus] = useMutation(
		gql`
			mutation InsertGoal($object: GoalInsertInput!) {
				insertGoalOne(object: $object) {
					id
					count
					duration
					interval
					activity {
						id
						goals {
							id
						}
					}
				}
			}
		`
	)

	const [updateGoalMutation, updateGoalStatus] = useMutation(
		gql`
			mutation UpdateGoalByPk($pkColumns: GoalPkColumnsInput!, $_set: GoalSetInput!) {
				updateGoalByPk(pkColumns: $pkColumns, _set: $_set) {
					id
					count
					duration
					interval
				}
			}
		`
	)

	// deleting an object keeps the primary fields in memory, allowing joins to be made through those,
	// but junction tables tracking many-to-many relationships are deleted before the query can be made through them,
	// resulting in an empty result set for those relationships.
	const [deleteGoalMutation, deleteGoalStatus] = useMutation(
		gql`
			mutation DeleteGoalByPk($id: Int!) {
				deleteGoalByPk(id: $id) {
					id
				}
			}
		`,
		{
			update(cache, { data: { deleteGoalByPk: event } }) {
				cache.evict({ id: `Goal:${event.id}` })
			},
		}
	)

	function createGoal(goal, activityId) {
		return createGoalMutation({
			variables: {
				object: {
					activityId: activityId,
					...goal,
				},
			},
		})
	}

	function updateGoal({ id, ...fields }) {
		updateGoalMutation({
			variables: { pkColumns: { id }, _set: fields },
		})
	}

	function deleteGoal(id) {
		deleteGoalMutation({ variables: { id } })
	}

	return {
		createGoal,
		createGoalStatus,
		updateGoal,
		updateGoalStatus,
		deleteGoal,
		deleteGoalStatus,
	}
}
