import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'

import {
	CheckCircle as CheckCircleIcon,
	RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material'

import { ListItem, TextInput } from 'components'

export default function AddTagDialog({
	initialSelectedTagIds,
	onSuccess,
}: {
	initialSelectedTagIds: number[]
	onSuccess: (selectedTags: { id: number; name: string; color: string }[]) => void
}) {
	const [open, setOpen] = useState(false)
	const [search, setSearch] = useState('')
	const [selectedTags, setSelectedTags] = useState<{ id: number; name: string; color: string }[]>(
		[]
	)

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const { data: { activities: tags = [] } = {}, loading } = useQuery(TAGS)

	function selectTag(tag: { id: number; name: string; color: string }) {
		setSelectedTags([...selectedTags, tag])
	}

	function unselectTag(id: number) {
		setSelectedTags(selectedTags.filter(x => x.id !== id))
	}

	function onClose() {
		setSelectedTags([])
		setOpen(false)
		setSearch('')
	}

	const isSelected = (id: number) => selectedTags.some(tag => tag.id === id)

	return (
		<>
			<Button onClick={() => setOpen(true)}>add tags</Button>
			<Dialog
				open={open}
				onClose={onClose}
				fullScreen={isMobile}
				maxWidth="lg"
				PaperComponent={Box}
				PaperProps={{
					sx: {
						bgcolor: theme => theme.palette.background.default,
					},
				}}
			>
				<DialogTitle>Add Tags</DialogTitle>
				<DialogContent>
					<TextInput
						value={search}
						onChange={setSearch}
						type="search"
						placeholder="search"
						fullWidth
					/>
					<List>
						{tags
							.filter(
								tag =>
									!initialSelectedTagIds.includes(tag.id) &&
									tag.name.toLowerCase().includes(search.toLowerCase())
							)
							.map(tag => (
								<ListItem
									key={tag.id}
									primary={
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Checkbox
												size="small"
												checked={isSelected(tag.id)}
												icon={<RadioButtonUncheckedIcon />}
												checkedIcon={<CheckCircleIcon color="action" />}
											/>
											<Typography
												sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
											>
												{tag.name}
											</Typography>
										</Box>
									}
									onClick={() => (isSelected(tag.id) ? unselectTag(tag.id) : selectTag(tag))}
								/>
							))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button color="inherit" onClick={onClose}>
						cancel
					</Button>
					<Button
						color="success"
						onClick={() => {
							onSuccess(selectedTags)
							onClose()
						}}
					>
						add selected tags
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

const TAGS = gql`
	query Tags {
		activities(orderBy: { allEventsCount: DESC }) {
			id
			name
			color
			description
			allEventsCount
			allEventsDuration
			childTags {
				child {
					id
					name
					color
				}
			}
			parentTags {
				parent {
					id
				}
			}
		}
	}
`
