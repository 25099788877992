import React from 'react'

import { SvgIcon } from '@mui/material'

export default function KronosIcon(props) {
	return (
		<SvgIcon viewBox="0 0 700 500" {...props}>
			{/* <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /> */}
			<path d="m484.6 144.91 39.621-39.586c-95.855-95.855-251.81-95.855-347.67 0l39.574 39.586c28.734-28.793 65.996-47.539 106.25-53.449v150.89h-100.13v56h100.12v228.26h56v-228.23h100.13v-56h-100.11v-150.89c40.234 5.9062 77.492 24.641 106.23 53.418z" />
		</SvgIcon>
	)
}
