import { useState, useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'
import { actions } from 'hooks'

import { Box, Button, List } from '@mui/material'
import { Loading, TextInput } from 'components'
import TreeView, { TreeNode } from '../pages/ActivitiesPage/TreeView'

export default function ActivityTreeView({
	tree,
	rootItems,
	rowContent,
	onRowClick,
	makeIsExpanded,
	listProps,
	getRowProps,
}: {
	tree: Map<number, { node; children: any[] }>
	rootItems: { node; children: any[] }[]
	rowContent: ({ id, name, allEventsCount, allEventsDuration, color, isParent }) => JSX.Element
	onRowClick?: (node) => void
	listProps?: React.HTMLAttributes<HTMLUListElement>
	makeIsExpanded?: (expanded: number[]) => (id: number) => boolean
	getRowProps?: (node, index: number) => React.HTMLAttributes<HTMLLIElement>
}) {
	return (
		<TreeView tree={tree} makeIsExpanded={makeIsExpanded} listProps={listProps}>
			{rootItems.map(({ node }) => (
				<TreeNode
					key={node.id}
					nodeId={node.id}
					rowContent={rowContent}
					onRowClick={onRowClick}
					getRowProps={getRowProps}
				/>
			))}
		</TreeView>
	)
}

export function useActivityTree({ activities, search }: { activities: any[]; search: string }) {
	const activityTree = useMemo(() => {
		const heirarchy = new Map<number, { node: any; children: any[] }>()

		for (const activity of activities) {
			heirarchy.set(activity.id, { node: activity, children: [] })

			for (const { child } of activity.childTags) {
				heirarchy.get(activity.id)?.children.push(child.id)
			}
		}

		return heirarchy
	}, [activities])

	const { filteredActivityTree, filteredRootActivities } = useMemo(() => {
		const result = new Map<number, { node: any; children: number[] }>()

		for (const activity of activities) {
			if (someChildMatchesSearch(activity.id, search, activityTree)) {
				result.set(activity.id, { node: activity, children: [] })
			}

			for (const { child } of activity.childTags) {
				if (someChildMatchesSearch(child.id, search, activityTree)) {
					result.get(activity.id)?.children.push(child.id)
				}
			}
		}

		return {
			filteredActivityTree: result,
			filteredRootActivities: Array.from(result.values()).filter(
				({ node }) => node.parentTags?.length === 0
			),
		}
	}, [activityTree, search])

	return { filteredActivityTree, filteredRootActivities }
}

function someChildMatchesSearch(
	id: number,
	search: string,
	tree: Map<number, { node; children: number[] }>
) {
	const { node, children } = tree.get(id)!

	return (
		node.name.toLowerCase().includes(search.toLowerCase()) ||
		children.some(id => someChildMatchesSearch(id, search, tree))
	)
}
