import { useMutation, gql } from '@apollo/client'

export default function useRemoveTagsFromEvent() {
	function removeTagsFromEvent(eventId: number, tagIds: number[]) {
		removeTagsFromEventMutation({
			variables: {
				eventId,
				activityIds: tagIds,
			},
			update(
				cache,
				{
					data: {
						deleteActivityEvent: { returning },
					},
				}
			) {
				for (const { activity, event } of returning) {
					cache.modify({
						id: cache.identify(activity),
						fields: {
							allEvents(existing, { readField }) {
								return existing.filter(ref => readField('id', ref) !== event.id)
							},
						},
					})
				}
			},
		})
	}

	// needs to update aggregates and event lists of all parent tags of activity
	const [removeTagsFromEventMutation, removeTagsFromEventStatus] = useMutation(gql`
		mutation RemoveTagFromEvent($eventId: Int!, $activityIds: [Int!]) {
			deleteActivityEvent(
				where: { activityId: { _in: $activityIds }, eventId: { _eq: $eventId } }
			) {
				returning {
					event {
						id
						tags {
							activity {
								id
							}
						}
					}
					activity {
						id
						allEventsDuration
						allEventsCount
					}
				}
			}
		}
	`)

	return {
		removeTagsFromEvent,
		removeTagsFromEventStatus,
	}
}
