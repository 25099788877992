import { useQuery, gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

import { Box, Divider, Paper, Stack, Typography } from '@mui/material'

import { ActivityGoal, Loading } from 'components'

export default function GoalsPage() {
	const { loading, data: { activities = [] } = {} } = useQuery(GET_GOALS, {
		variables: {
			totalDurationTodayArgs: {
				start_time: DateTime.fromObject({ ordinal: DateTime.now().ordinal }).toISO(),
			},
			totalDurationWeekArgs: {
				start_time: DateTime.fromObject({ weekNumber: DateTime.now().weekNumber }).toISO(),
			},
			totalDurationMonthArgs: {
				start_time: DateTime.fromObject({ month: DateTime.now().month }).toISO(),
			},
			totalDurationYearArgs: {
				start_time: DateTime.fromObject({ year: DateTime.now().year }).toISO(),
			},
		},
	})
	const navigate = useNavigate()

	return (
		<Loading loading={loading}>
			{activities.map(activity => (
				<Box key={activity.id} sx={{ p: 1 }}>
					<Typography
						variant={'h4'}
						color={activity.color}
						sx={{ pb: 1 }}
						onClick={() => navigate(`/activity/${activity.id}`)}
					>
						{activity.name}
					</Typography>
					<Stack
						// divider={<Divider flexItem />}
						spacing={1}
						sx={{
							borderRadius: 4,
							backgroundColor: '#0D0D0D', //theme => theme.palette.grey['900'],
						}}
					>
						{activity.goals.map(goal => (
							<ActivityGoal key={goal.id} goal={goal} activity={activity} isEditing={false} />
						))}
					</Stack>
				</Box>
			))}
		</Loading>
	)
}

const GET_GOALS = gql`
	query Goals(
		$totalDurationTodayArgs: allEventsDuration_activity_args! = {}
		$totalDurationWeekArgs: allEventsDuration_activity_args! = {}
		$totalDurationMonthArgs: allEventsDuration_activity_args! = {}
		$totalDurationYearArgs: allEventsDuration_activity_args! = {}
	) {
		activities(where: { goalsAggregate: { count: { predicate: { _gt: 0 } } } }) {
			id
			name
			color
			totalDurationToday: allEventsDuration(args: $totalDurationTodayArgs)
			totalDurationWeek: allEventsDuration(args: $totalDurationWeekArgs)
			totalDurationMonth: allEventsDuration(args: $totalDurationMonthArgs)
			totalDurationYear: allEventsDuration(args: $totalDurationYearArgs)
			goals {
				id
				count
				duration
				interval
			}
		}
	}
`
