import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'

import { actions } from 'hooks'

import { Box, IconButton } from '@mui/material'
import { CheckRounded as CheckIcon } from '@mui/icons-material'

import { ActivityMenu, DeleteConfirmationDialog, EventDetail, Loading } from 'components'

export default function EventPage() {
	const { id } = useParams()
	const navigate = useNavigate()

	const { data: { event } = {}, loading, error } = useQuery(GET_EVENT, {
		variables: { id },
	})

	const { addTagsToEvent } = actions.useAddTagsToEvent()
	const { deleteEvent } = actions.useDeleteEvent()
	const { updateEvent } = actions.useUpdateEvent()
	const { removeTagsFromEvent } = actions.useRemoveTagsFromEvent()

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
	const [isEditing, setIsEditing] = useState(false)

	return (
		<Loading loading={loading}>
			{event && (
				<>
					<Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
						{isEditing ? (
							<IconButton size="small" onClick={() => setIsEditing(false)}>
								<CheckIcon color="success" fontSize="medium" />
							</IconButton>
						) : (
							<ActivityMenu
								setIsEditing={setIsEditing}
								setIsDeleteDialogOpen={setIsDeleteDialogOpen}
							/>
						)}
					</Box>
					<EventDetail
						event={{
							tags: event.tags.map(({ activity }) => activity),
							description: event.description,
							startTime: DateTime.fromISO(event.startTime),
							endTime: event.endTime ? DateTime.fromISO(event.endTime) : null,
							duration: event.duration,
						}}
						isEditing={isEditing}
						isTagSelected={id => event.tags.some(({ activity }) => activity.id === id)}
						onAddTag={tag => addTagsToEvent(event.id, [tag.id])}
						onRemoveTag={tagId => removeTagsFromEvent(event.id, [tagId])}
						onDescriptionChange={description =>
							updateEvent({
								id: event.id,
								description,
							})
						}
						onEndTimeChange={endTime => updateEvent({ id: event.id, endTime })}
						onStartTimeChange={startTime => updateEvent({ id: event.id, startTime })}
					/>
					<DeleteConfirmationDialog
						open={isDeleteDialogOpen}
						setOpen={setIsDeleteDialogOpen}
						onClick={() => {
							navigate(-1)
							deleteEvent(event.id)
						}}
					/>
				</>
			)}
		</Loading>
	)
}

const EVENT_FRAGMENT = gql`
	fragment EventFragment on Event {
		id
		startTime
		endTime
		duration
		description
		tags {
			activity {
				id
				name
				color
			}
		}
	}
`

const GET_EVENT = gql`
	query GetEvent($id: Int!) {
		event: eventByPk(id: $id) {
			...EventFragment
		}
	}
	${EVENT_FRAGMENT}
`
