import { DateTime } from 'luxon'

import generateDurationString from './generateDurationString'

export default function generateTimeWithDurationString({
	startTime,
	endTime,
	duration,
}: {
	startTime: string
	endTime?: string
	duration?: number
}) {
	return `${toSimpleTime(startTime)} ${endTime ? ` | ${generateDurationString(duration)}` : ''}`
}

const toSimpleTime = (iso: string) => DateTime.fromISO(iso).toLocaleString(DateTime.TIME_SIMPLE)
