import { useState } from 'react'
import { DateTime } from 'luxon'

import { useCreateEvent, useEditEvent, useStartActivities } from 'hooks'

import { Box, Button } from '@mui/material'

import { EventDetail } from 'components'

export default function CreatePage() {
	const { createEvent, createEventStatus } = useCreateEvent()
	const { startActivities, startActivitiesStatus } = useStartActivities()

	const {
		event,
		isTagSelected,
		selectTag,
		deselectTag,
		setDescription,
		setStartTime,
		setEndTime,
		reset,
	} = useEditEvent({
		description: '',
		startTime: DateTime.now().toISO()!,
		endTime: null,
		duration: null,
		tags: [],
	})

	return (
		<>
			<EventDetail
				event={event}
				isEditing={true}
				isTagSelected={isTagSelected}
				onAddTag={selectTag}
				onRemoveTag={deselectTag}
				onDescriptionChange={setDescription}
				onEndTimeChange={setEndTime}
				onStartTimeChange={setStartTime}
			/>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					gap: 2,
					my: 5,
				}}
			>
				<Button
					color={'success'}
					sx={{ color: 'white' }}
					variant="contained"
					size="large"
					disabled={createEventStatus.loading}
					onClick={async () => {
						await createEvent({
							...event,
							startTime: event.startTime.toISO()!,
							endTime: event.endTime?.toISO() || null,
						})
						reset()
					}}
				>
					Create Event
				</Button>
				<Button
					color={'success'}
					sx={{ color: 'white' }}
					variant="contained"
					size="large"
					disabled={startActivitiesStatus.loading}
					onClick={async () => {
						await startActivities({
							...event,
							startTime: event.startTime.toISO()!,
							endTime: event.endTime?.toISO() || null,
						})
						reset()
					}}
				>
					Start Event
				</Button>
			</Box>
		</>
	)
}
