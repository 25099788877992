import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { useLists, useItems } from 'hooks'
import { DateTime } from 'luxon'

import { Box, Divider, List, Checkbox } from '@mui/material'
import {
	CheckCircle as CheckCircleIcon,
	RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material'
import { DeleteButton, ListItem, Loading, TextInput } from 'components'

export default function ListPage() {
	const { id } = useParams()

	const { updateList } = useLists()
	const { createItem } = useItems()

	const { data: { list } = {}, loading, error } = useQuery(GET_LIST, { variables: { id } })

	console.log(loading, list)
	return (
		<Loading loading={loading}>
			{list && (
				<>
					<TextInput
						placeholder="name"
						value={list.name}
						onUpdate={name => updateList({ id, name })}
						multiline
					/>

					<List>
						<ListItem
							primary={
								<TextInput
									value={''}
									onUpdate={name => name.length && createItem({ name }, list)}
									placeholder={'new item'}
									multiline
									fullWidth
									clearOnSubmit
									keepFocusOnSubmit
								/>
							}
						/>
						{list.uncompletedListItems.map(({ item }) => (
							<TodoListItem item={item} key={item.id} />
						))}
					</List>
					<Divider />
					<List>
						{list.completedListItems.map(({ item }) => (
							<TodoListItem key={item.id} item={item} />
						))}
					</List>
				</>
			)}
		</Loading>
	)
}

function TodoListItem({ item }) {
	const { deleteItem, updateItem } = useItems()

	return (
		<ListItem
			primary={
				<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
					<Checkbox
						size="small"
						checked={item.completedAt}
						onClick={() =>
							updateItem({
								id: item.id,
								completedAt: item.completedAt ? null : DateTime.now().toISO(),
							})
						}
						icon={<RadioButtonUncheckedIcon />}
						checkedIcon={<CheckCircleIcon color="action" />}
					/>
					<TextInput
						value={item.name}
						onUpdate={name => updateItem({ id: item.id, name })}
						multiline
						fullWidth
					/>
				</Box>
			}
			secondaryAction={<DeleteButton onClick={() => deleteItem(item.id)} />}
		/>
	)
}

const GET_LIST = gql`
	query List($id: Int!) {
		list: listByPk(id: $id) {
			id
			name
			description
			createdAt
			updatedAt
			uncompletedListItems: listItems(where: { item: { completedAt: { _isNull: true } } }) {
				item {
					id
					name
					completedAt
					dueBy
					createdAt
				}
			}
			completedListItems: listItems(where: { item: { completedAt: { _isNull: false } } }) {
				item {
					id
					name
					completedAt
					dueBy
					createdAt
				}
			}
		}
	}
`
