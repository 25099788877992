import { useMutation, gql } from '@apollo/client'

export default function useDeleteEvent() {
	function deleteEvent(id: number) {
		deleteEventMutation({ variables: { id } })
	}

	// deleting an object keeps the primary fields in memory, allowing joins to be made through those,
	// but junction tables tracking many-to-many relationships are deleted before the query can be made through them,
	// resulting in an empty result set for those relationships.
	const [deleteEventMutation, deleteEventStatus] = useMutation(
		gql`
			mutation DeleteEventByPk($id: Int!) {
				deleteEventByPk(id: $id) {
					id
					tags {
						activity {
							id
							currentEvent {
								id
							}
							allEventsCount
							allEventsDuration
							parentTags {
								parent {
									id
									allEventsCount
									allEventsDuration
								}
							}
						}
					}
				}
			}
		`,
		{
			update(cache, { data: { deleteEventByPk: event } }) {
				cache.evict({ id: `Event:${event.id}` })
			},
		}
	)

	return {
		deleteEvent,
		deleteEventStatus,
	}
}
