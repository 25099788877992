import { useState } from 'react'

import { IconButton, Stack, Typography } from '@mui/material'
import { ClickAwayListener } from '@mui/base'
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'

import DeleteButton from './DeleteButton'

export default function DeleteButtonWithInlineConfirmation({ onClick }) {
	const [activated, setActivated] = useState(false)

	return (
		<>
			{activated ? (
				<ClickAwayListener onClickAway={() => setActivated(false)}>
					<Stack sx={{}} direction={'row'} alignItems={'center'}>
						<Typography variant="body2" color="text.secondary" paddingRight={1}>
							delete this?
						</Typography>
						<IconButton size="small" onClick={onClick}>
							<CheckIcon color="success" fontSize="inherit" />
						</IconButton>
						<IconButton size="small" onClick={() => setActivated(false)}>
							<CloseIcon color="error" fontSize="inherit" />
						</IconButton>
					</Stack>
				</ClickAwayListener>
			) : (
				<DeleteButton onClick={() => setActivated(true)} />
			)}
		</>
	)
}
