import React, { useRef } from 'react'
import { createRoot } from 'react-dom/client'

import { initializeApp } from 'firebase/app'

import App from 'App/App'
import firebaseConfig from './firebaseConfig'

import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import './index.css'

initializeApp(firebaseConfig)

createRoot(document.getElementById('root')!).render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
