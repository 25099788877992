import { useMutation, gql } from '@apollo/client'
import { toISOStringWithTimezone } from 'utils'

export default function useStopActivity() {
	function stopActivity(eventId: number, totalDurationArgs) {
		stopActivityMutation({
			variables: {
				activityWhere: { currentEventId: { _eq: eventId } },
				activitySet: { currentEventId: null },
				eventPkColumns: { id: eventId },
				eventSet: { endTime: toISOStringWithTimezone(new Date()) },
				...totalDurationArgs,
			},
		})
	}

	const [stopActivityMutation, stopActivityStatus] = useMutation(gql`
		mutation StopActivity(
			$activityWhere: ActivityBoolExp!
			$activitySet: ActivitySetInput
			$eventPkColumns: EventPkColumnsInput!
			$eventSet: EventSetInput!
			$totalDurationTodayArgs: allEventsDuration_activity_args! = {}
			$totalDurationWeekArgs: allEventsDuration_activity_args! = {}
			$totalDurationMonthArgs: allEventsDuration_activity_args! = {}
			$totalDurationYearArgs: allEventsDuration_activity_args! = {}
		) {
			updateEventByPk(pkColumns: $eventPkColumns, _set: $eventSet) {
				id
				startTime
				endTime
				duration
			}
			updateActivity(where: $activityWhere, _set: $activitySet) {
				returning {
					id
					currentEvent {
						id
					}
					allEventsDuration
					totalDurationToday: allEventsDuration(args: $totalDurationTodayArgs)
					totalDurationWeek: allEventsDuration(args: $totalDurationWeekArgs)
					totalDurationMonth: allEventsDuration(args: $totalDurationMonthArgs)
					totalDurationYear: allEventsDuration(args: $totalDurationYearArgs)
				}
			}
		}
	`)

	return {
		stopActivity,
		stopActivityStatus,
	}
}
