import { useMutation, gql } from '@apollo/client'

export default function useCreateActivity() {
	function createActivity(activity) {
		createActivityMutation({
			variables: { object: activity },
			update(cache, { data: { insertActivityOne: activity } }) {
				cache.modify({
					fields: {
						activities(existing, { toReference }) {
							// probably needs to be better ordered
							return [...existing, toReference(activity)]
						},
					},
				})
			},
		})
	}

	const [createActivityMutation, createActivityStatus] = useMutation(
		gql`
			mutation InsertActivity($object: ActivityInsertInput!) {
				insertActivityOne(object: $object) {
					id
					name
					color
					description
					allEventsCount
					allEventsDuration
					childTags {
						child {
							id
						}
					}
					parentTags {
						parent {
							id
						}
					}
				}
			}
		`
	)

	return {
		createActivity,
		createActivityStatus,
	}
}
