import { useMutation, gql } from '@apollo/client'

export default function useRemoveTagFromActivity() {
	function removeTagFromActivity({ parentActivityId, childActivityId }) {
		removeTagFromActivityMutation({
			variables: { parentActivityId, childActivityId },
		})
	}

	// needs to update parent tag event list as well as event list and aggregates of all parent tags
	const [removeTagFromActivityMutation, removeTagFromActivityStatus] = useMutation(gql`
		mutation RemoveTagFromTag($childActivityId: Int!, $parentActivityId: Int!) {
			deleteActivityActivity(
				where: {
					childActivityId: { _eq: $childActivityId }
					parentActivityId: { _eq: $parentActivityId }
				}
			) {
				returning {
					child {
						id
						parentTags {
							parent {
								id
							}
						}
					}
					parent {
						id
						allEventsDuration
						allEventsCount
						childTags {
							child {
								id
							}
						}
					}
				}
			}
		}
	`)

	return {
		removeTagFromActivity,
		removeTagFromActivityStatus,
	}
}
