import { DateTime, Duration, DurationLikeObject } from 'luxon'

import { AxisBottom, AxisLeft } from '@visx/axis'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Grid } from '@visx/grid'
import { Group } from '@visx/group'
import { BarRounded, Line, LinePath } from '@visx/shape'
import { Text } from '@visx/text'
import { Label } from '@visx/annotation'
import { useTheme } from '@mui/material'

interface Datum {
	count: number
	duration: number
	date: string
}

const MARGIN = {
	top: 20,
	right: 30,
	bottom: 20,
	left: 30,
}

function buildChartTheme({}) {
	return {
		axis: {
			x: {
				axisLine: {
					stroke: '#222',
					strokeWidth: 2,
				},
				tick: {
					label: {
						fontSize: 11,
						fill: 'rgb(73,80,87)',
						fontWeight: 200,
						stroke: 'none',
					},
					length: 4,
					line: {
						strokeWidth: 1,
						stroke: 'white',
					},
				},
			},
		},
		grid: {
			rowLineStyle: {
				stroke: '#222',
			},
			columnLineStyle: {
				stroke: 'none',
			},
		},
	}
}
const x = (d: Datum) => d.date
const y = (d: Datum) => d.duration / 60 / 60

export default function BarChart({
	data,
	step,
	width,
	height,
}: {
	data: Datum[]
	step: keyof DurationLikeObject
	width: number
	height: number
}) {
	const theme = useTheme()
	const chartTheme = buildChartTheme(theme)

	const dateNow = DateTime.fromObject({ month: DateTime.now().month })
	const dateOneYearAgo = dateNow.minus({ year: 1 })

	const completedData = fillMissingValues(data, dateOneYearAgo)

	const bounds = {
		x: {
			min: 0,
			max: width - MARGIN.left - MARGIN.right,
		},
		y: {
			min: 0,
			max: height - MARGIN.top - MARGIN.bottom,
		},
	}

	const scales = {
		x: scaleBand({
			range: [bounds.x.min, bounds.x.max],
			domain: completedData.map(x),
			padding: 0.4,
		}),
		y: scaleLinear({
			range: [bounds.y.max, bounds.y.min],
			domain: [0, Math.max(...completedData.map(y))],
			clamp: true,
			nice: true,
		}),
	}

	return (
		<svg width={width} height={height}>
			<Grid
				top={MARGIN.top}
				left={MARGIN.left}
				xScale={scales.x}
				yScale={scales.y}
				width={bounds.x.max}
				height={bounds.y.max}
				rowLineStyle={chartTheme.grid.rowLineStyle}
				columnLineStyle={chartTheme.grid.columnLineStyle}
				numTicksRows={5}
			/>
			{completedData
				.filter(d => y(d))
				.map(d => (
					<Group key={`bar-${x(d)}`} left={MARGIN.left} top={MARGIN.top}>
						<BarRounded
							width={scales.x.bandwidth()}
							height={bounds.y.max - scales.y(y(d))}
							x={scales.x(x(d))!}
							y={scales.y(y(d))}
							fill={'white'}
							radius={5}
							top
						/>
						{/* <Text
							fill="#AAA"
							fontSize={11}
							fontWeight={100}
							x={scales.x(x(d))}
							y={scales.y(y(d))}
							dx={scales.x.bandwidth() / 2}
							dy={-10}
							style={{ textAlign: 'center' }}
							textAnchor={'middle'}
						>
							{Math.round(y(d))}
						</Text> */}
					</Group>
				))}
			<AxisBottom
				scale={scales.x}
				top={bounds.y.max + MARGIN.top}
				left={bounds.x.min + MARGIN.left}
				tickFormat={value => DateTime.fromISO(value).monthShort ?? ''}
				tickLength={chartTheme.axis.x.tick.length}
				tickStroke={chartTheme.axis.x.tick.line.stroke}
				tickLabelProps={() => ({ textAnchor: 'middle', ...chartTheme.axis.x.tick.label })}
			/>
			<AxisLeft
				scale={scales.y}
				hideAxisLine
				numTicks={5}
				top={bounds.y.min + MARGIN.top}
				left={bounds.x.min + MARGIN.left}
				tickLength={chartTheme.axis.x.tick.length}
				tickStroke={chartTheme.axis.x.tick.line.stroke}
				tickFormat={value => (value.valueOf() ? `${value.valueOf()}h` : '0')}
				tickLabelProps={() => ({
					textAnchor: 'end',
					verticalAnchor: 'middle',
					dx: '-0.25em',
					...chartTheme.axis.x.tick.label,
				})}
			/>
		</svg>
	)
}

function GoalLine({ margin, width }) {
	return (
		<>
			<Line
				from={{ x: margin.left, y: 100 }}
				to={{ x: width - margin.right, y: 100 }}
				stroke="green"
				strokeWidth={2}
			/>
			<Label
				title={'goal'}
				fontColor="white"
				showAnchorLine={false}
				showBackground={false}
				titleFontSize={12}
				backgroundPadding={0}
				titleFontWeight={200}
				x={width - margin.right + 5}
				y={100}
				verticalAnchor="middle"
				horizontalAnchor="start"
			/>
		</>
	)
}

function fillMissingValues(data: Datum[], startDate: DateTime) {
	const newData = Array(13)

	for (let i = 0; i < 13; i++) {
		const date = startDate.plus({ month: i }).toISODate()

		const { count = null, duration = null } =
			data.find(datum => DateTime.fromISO(datum.date, { zone: 'utc' }).toISODate() === date) || {}

		newData[i] = {
			date,
			count,
			duration,
		}
	}

	return newData
}
