import { useMutation, gql } from '@apollo/client'

export default function useUpdateEvent() {
	function updateEvent({ id, ...fields }) {
		// todo: update position in event list of feed and all tags based on start time
		// todo: update aggregates of all tags
		updateEventMutation({
			variables: { pkColumns: { id }, _set: fields },
		})
	}

	const [updateEventMutation, updateEventStatus] = useMutation(
		gql`
			mutation UpdateEventByPk($pkColumns: EventPkColumnsInput!, $_set: EventSetInput!) {
				updateEventByPk(pkColumns: $pkColumns, _set: $_set) {
					id
					startTime
					endTime
					duration
					description
					tags {
						activity {
							parentTags {
								parent {
									id
									allEventsDuration
								}
							}
							id
							allEventsDuration
						}
					}
				}
			}
		`
	)

	return {
		updateEvent,
		updateEventStatus,
	}
}
