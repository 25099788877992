import { useNavigate } from 'react-router-dom'
import { Box, IconButton, Typography } from '@mui/material'
import { KronosIcon, Link } from 'components'

export default function () {
	const navigate = useNavigate()
	return (
		<Box
			sx={{
				position: 'absolute',
				top: '40%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				textAlign: 'center',
			}}
		>
			<Link to="/">
				<KronosIcon fontSize="large" />
			</Link>
			<Typography variant="h2">Uhhhhhhh error</Typography>
			<Typography variant="caption">Try going back or refreshing the page</Typography>
		</Box>
	)
}
