import { useReactiveVar, useMutation, gql } from '@apollo/client'
import { DateTime } from 'luxon'

import { getCoordinateString, mergeSortedLists } from 'utils'

export interface Event {
	startTime?: string
	endTime?: string | null
	description?: string
	coordinates?: string
	tags: { id: number; name: string }[]
}

export default function useCreateEvent() {
	async function createEvent(event: Event) {
		return createEventMutation({
			variables: {
				object: await createGqlEvent(event),
			},
			// todo: add to list and update aggregates of every parent tag
			update(cache, { data: { insertEventOne: event } }) {
				// add to the list of every activity with an events field
				for (const { activity } of event.tags) {
					cache.modify({
						id: cache.identify(activity),
						fields: {
							allEvents(existing, { toReference }) {
								return [toReference(event), ...existing]
							},
						},
					})
				}

				// add to the feed
				cache.modify({
					fields: {
						events(existing, { toReference }) {
							return [toReference(event), ...existing]
						},
					},
				})
			},
		})
	}

	const [createEventMutation, createEventStatus] = useMutation(
		gql`
			mutation InsertEvent($object: EventInsertInput!) {
				insertEventOne(object: $object) {
					id
					startTime
					endTime
					duration
					description
					tags {
						activity {
							id
							allEventsCount
							allEventsDuration
						}
					}
					parentEvents(orderBy: { startTime: ASC }) {
						id
						description
						tags {
							activity {
								id
								name
								color
							}
						}
					}
					childEventsCount
					childEvents(orderBy: { startTime: ASC }) {
						id
						description
						startTime
						endTime
						duration
						tags {
							activity {
								id
								name
								color
							}
						}
					}
				}
			}
		`
	)

	return {
		createEvent,
		createEventStatus,
	}
}

export async function createGqlEvent({
	startTime = DateTime.now().toISO()!,
	endTime = null,
	tags,
	description = '',
	coordinates,
}: Event) {
	return {
		startTime,
		endTime,
		description,
		coordinates: coordinates || (await getCoordinateString()),
		tags: {
			data: tags.map(tag => ({
				activity: {
					data: { id: tag.id, name: tag.name },
					onConflict: { constraint: 'activities_pkey', updateColumns: 'name' },
				},
			})),
		},
	}
}
