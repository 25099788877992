import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { MenuItem, Select, FormControl, InputLabel } from '@mui/material'

import { Box } from '@mui/material'
import { EventList, Loading } from 'components'
import ActivityHeader from './ActivityHeader'

const eventsByDateStep = 'month'

export default function ActivityPage() {
	const { id } = useParams()
	const { data: { activity } = {}, loading, fetchMore } = useQuery(GET_ACTIVITY, {
		variables: {
			id,
			eventsByDateStep,
			totalDurationTodayArgs: {
				start_time: DateTime.fromObject({ ordinal: DateTime.now().ordinal }).toISO(),
			},
			totalDurationWeekArgs: {
				start_time: DateTime.fromObject({ weekNumber: DateTime.now().weekNumber }).toISO(),
			},
			totalDurationMonthArgs: {
				start_time: DateTime.fromObject({ month: DateTime.now().month }).toISO(),
			},
			totalDurationYearArgs: {
				start_time: DateTime.fromObject({ year: DateTime.now().year }).toISO(),
			},
		},
	})

	const [isEditing, setIsEditing] = useState(false)

	const [sentry] = useInfiniteScroll({
		loading,
		hasNextPage: activity?.events.length < activity?.allEventsCount,
		onLoadMore: () =>
			fetchMore({
				query: GET_ACTIVITY_EVENTS,
				variables: {
					id,
					eventWhere: {
						startTime: { _lt: activity?.events[activity?.events.length - 1].startTime },
					},
				},
			}),
		rootMargin: '0px 0px 400px 0px',
	})

	return (
		<Loading loading={loading}>
			{activity && (
				<Box>
					<ActivityHeader
						activity={activity}
						isEditing={isEditing}
						setIsEditing={setIsEditing}
						eventsByDateStep={eventsByDateStep}
					/>
					<EventList
						events={activity.events}
						isCurrentEvent={(eventId: string) => activity.currentEvent?.id === eventId}
						isEditing={isEditing}
					/>
					<div ref={sentry} />
				</Box>
			)}
		</Loading>
	)
}

function ListControls({ sortValue, setSortValue }) {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
			<FormControl size="small">
				<InputLabel>sort by</InputLabel>
				<Select label="sort by" value={sortValue} onChange={e => setSortValue(e.target.value)}>
					<MenuItem value={'newest'}>newest</MenuItem>
					<MenuItem value={'oldest'}>oldest</MenuItem>
					<MenuItem value={'longest'}>longest</MenuItem>
					<MenuItem value={'shortest'}>shortest</MenuItem>
				</Select>
			</FormControl>
		</Box>
	)
}

const GET_ACTIVITY = gql`
	query Activity(
		$id: Int!
		$eventLimit: Int = 10
		$eventWhere: EventBoolExp
		$eventsByDateStep: String
		$totalDurationTodayArgs: allEventsDuration_activity_args! = {}
		$totalDurationWeekArgs: allEventsDuration_activity_args! = {}
		$totalDurationMonthArgs: allEventsDuration_activity_args! = {}
		$totalDurationYearArgs: allEventsDuration_activity_args! = {}
	) {
		activity: activityByPk(id: $id) {
			id
			name
			description
			color
			parentTags {
				parent {
					id
					name
					color
				}
			}
			childTags {
				child {
					id
					name
					color
					allEventsCount
					allEventsDuration
				}
			}
			currentEvent {
				id
				startTime
			}
			goals {
				id
				count
				duration
				interval
			}
			allEventsCount
			allEventsDuration
			totalDurationToday: allEventsDuration(args: $totalDurationTodayArgs)
			totalDurationWeek: allEventsDuration(args: $totalDurationWeekArgs)
			totalDurationMonth: allEventsDuration(args: $totalDurationMonthArgs)
			totalDurationYear: allEventsDuration(args: $totalDurationYearArgs)
			eventsByDate(args: { step: $eventsByDateStep }) {
				date
				count
				duration
			}
			eventsGroupedByDay: eventsByDate(args: { step: "day" }) {
				date
			}
			events: allEvents(orderBy: { startTime: DESC }, limit: $eventLimit, where: $eventWhere) {
				id
				startTime
				endTime
				duration
				description
				tags {
					activity {
						id
						name
						color
					}
				}
				parentEvents(orderBy: { startTime: ASC }) {
					id
					description
					tags {
						activity {
							id
							name
							color
						}
					}
				}
				childEventsCount
				childEvents(orderBy: { startTime: ASC }) {
					id
					description
					startTime
					endTime
					duration
					tags {
						activity {
							id
							name
							color
						}
					}
				}
			}
		}
	}
`

const GET_ACTIVITY_EVENTS = gql`
	query ActivityEvents($id: Int!, $eventLimit: Int = 10, $eventWhere: EventBoolExp) {
		activity: activityByPk(id: $id) {
			id
			events: allEvents(orderBy: { startTime: DESC }, limit: $eventLimit, where: $eventWhere) {
				id
				startTime
				endTime
				duration
				description
				tags {
					activity {
						id
						name
						color
					}
				}
				parentEvents(orderBy: { startTime: ASC }) {
					id
					description
					tags {
						activity {
							id
							name
							color
						}
					}
				}
				childEventsCount
				childEvents(orderBy: { startTime: ASC }) {
					id
					description
					startTime
					endTime
					duration
					tags {
						activity {
							id
							name
							color
						}
					}
				}
			}
		}
	}
`
