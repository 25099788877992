import React, { useMemo, useState, createContext } from 'react'

import { ThemeProvider } from '@mui/material'

import { createTheme } from 'utils'

export const Context = createContext()
export function Provider({ children }) {
	const [mode, setMode] = useState('dark')
	const theme = useMemo(() => createTheme(mode), [mode])

	const toggleMode = () => setMode(mode => (mode === 'light' ? 'dark' : 'light'))

	return (
		<Context.Provider
			value={{
				mode,
				toggleMode,
				theme,
			}}
		>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</Context.Provider>
	)
}

export default {
	Context,
	Provider,
}
