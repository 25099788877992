import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from 'firebase/auth'
import { createBrowserRouter, useLocation, useOutlet, RouterProvider } from 'react-router-dom'
import { useTransition, animated, config } from '@react-spring/web'

import routes from './routes'
import { Container, CssBaseline, useMediaQuery, useTheme } from '@mui/material'

import AppBar from './AppBar'
import MobileNavBar, { BOTTOM_NAV_HEIGHT_PX } from './MobileNavBar'
import MobileHeader from './MobileHeader'
import ApolloProviderWithAuth from './apollo'
import { Loading } from 'components'
import { Theme } from 'context'
import { ErrorPage } from 'pages'

export default function App() {
	return (
		<ApolloProviderWithAuth>
			<Theme.Provider>
				<CssBaseline />
				<RouterProvider router={router} />
			</Theme.Provider>
		</ApolloProviderWithAuth>
	)
}

function Root() {
	const [user, loading] = useAuthState(getAuth())
	const outlet = useOutlet()
	const location = useLocation()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const transitions = useTransition(outlet, {
		key: location.pathname,
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: {
			...config.stiff,
			duration: 75,
		},
		exitBeforeEnter: true,
	})

	return (
		<Loading loading={loading}>
			{/* {isMobile ? <MobileHeader /> : <AppBar />} */}
			<Container sx={{ pb: isMobile ? `${BOTTOM_NAV_HEIGHT_PX}px` : undefined, pt: 2 }}>
				{!isMobile && <AppBar />}
				{transitions((style, outlet) => (
					<animated.div style={style}>{outlet}</animated.div>
				))}
			</Container>
			{isMobile && <MobileNavBar />}
		</Loading>
	)
}

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: routes,
		errorElement: <ErrorPage />,
	},
])
