import {
	ActivitiesPage,
	ActivityPage,
	CreatePage,
	DayViewPage,
	EventPage,
	GoalsPage,
	HomePage,
	ListsPage,
	ListPage,
	PomodoroPage,
} from 'pages'

export default [
	{
		index: true,
		element: <HomePage />,
	},
	// {
	// 	path: 'events',
	// 	element: <EventsPage />,
	// },
	{
		path: 'activities',
		element: <ActivitiesPage />,
		// 	children: [
		// 		// {
		// 		// 	index: true,
		// 		// 	element: <Test />,
		// 		// },
		// 		// {
		// 		// 	path: ':activityId',
		// 		// 	element: <ActivityPage />,
		// 		// },
		// 	],
	},
	{
		path: 'goals',
		element: <GoalsPage />,
	},
	{
		path: 'activity/:id',
		element: <ActivityPage />,
	},
	{
		path: 'create',
		element: <CreatePage />,
	},
	{
		path: 'event/:id',
		element: <EventPage />,
	},
	{
		path: 'lists',
		element: <ListsPage />,
	},
	{
		path: 'list/:id',
		element: <ListPage />,
	},
	{
		path: 'pomodoro',
		element: <PomodoroPage />,
	},
	{
		path: 'day-view',
		element: <DayViewPage />,
	},
	// {
	// 	path: 'user',
	// 	element: <UserPage />,
	// },
]
