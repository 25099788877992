import { useMutation, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { mergeSortedLists } from 'utils'

import useCreateEvent, { Event, createGqlEvent } from './useCreateEvent'

export default function useStartActivities() {
	const { createEvent, createEventStatus } = useCreateEvent()

	async function startActivities(event: Event) {
		const {
			data: {
				insertEventOne: { id: eventId },
			},
		} = await createEvent(event)

		return startActivitiesMutation({
			variables: {
				where: { id: { _in: event.tags.map(tag => tag.id) } },
				set: { currentEventId: eventId },
			},
		})
	}

	const [startActivitiesMutation, startActivitiesStatus] = useMutation(gql`
		mutation UpdateActivities($where: ActivityBoolExp!, $set: ActivitySetInput) {
			updateActivity(where: $where, _set: $set) {
				returning {
					id
					currentEvent {
						id
					}
				}
			}
		}
	`)

	return {
		startActivities,
		startActivitiesStatus: {
			...startActivitiesStatus,
			...createEventStatus,
			loading: startActivitiesStatus.loading || createEventStatus.loading,
		},
	}
}
