import { useState, useRef } from 'react'
import { gql, useQuery } from '@apollo/client'

import { useActivityTree } from './ActivityTreeView'

import {
	Box,
	Checkbox,
	Chip,
	InputBase,
	InputLabel,
	Popper,
	Typography,
	useAutocomplete,
} from '@mui/material'

import { unstable_useForkRef as useForkRef } from '@mui/utils'

import {
	CheckCircle as CheckCircleIcon,
	RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material'

import { ActivityTreeView, Caption } from 'components'

export default function TreeAutocomplete({
	tags,
	label = '',
	isTagSelected,
	onAddTag,
	onRemoveTag,
}: {
	tags: Tag[]
	label?: string
	isTagSelected: (id: number) => boolean
	onAddTag: (tag: Tag) => void
	onRemoveTag: (id: number) => void
}) {
	const { data: { activities = [] } = {}, loading } = useQuery<{ activities: Activity[] }>(TAGS)

	const {
		id,
		getRootProps,
		getInputProps,
		getInputLabelProps,
		getListboxProps,
		getTagProps,
		getOptionProps,
		inputValue,
		popupOpen,
		anchorEl,
		setAnchorEl,
	} = useAutocomplete({
		options: [],
		getOptionLabel: ({ name }) => name,
		multiple: true,
		disableCloseOnSelect: true,
		value: tags,
	})

	const { filteredRootActivities, filteredActivityTree } = useActivityTree({
		activities,
		search: inputValue,
	})

	const someChildIsSelected = (id: number) =>
		filteredActivityTree
			.get(id)
			?.children.some(childId => isTagSelected(childId) || someChildIsSelected(childId))

	const ref = useRef()
	const rootRef = useForkRef(ref, setAnchorEl)

	return (
		<>
			<Box {...getRootProps()} ref={rootRef}>
				<InputLabel {...getInputLabelProps()}>
					<Caption>{label}</Caption>
				</InputLabel>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'center',
						borderRadius: 4,
						bgcolor: theme => theme.palette.background.paper,
						p: 2,
						gap: 1,
					}}
				>
					{tags.map((tag, index) => (
						<Chip
							{...getTagProps({ index })}
							label={tag.name}
							sx={{ color: tag.color }}
							onDelete={() => onRemoveTag(tag.id)}
						/>
					))}
					<InputBase
						inputProps={getInputProps()}
						sx={{
							flex: '1 0 auto',
							ml: 2,
						}}
						id={id}
					/>
				</Box>
			</Box>
			{anchorEl && (
				<Popper open={popupOpen} anchorEl={anchorEl}>
					<Box
						sx={{
							bgcolor: theme => theme.palette.background.paper,
							width: anchorEl.clientWidth,
							maxHeight: '75vh',
							borderRadius: 4,
							mt: 1,
							overflowY: 'scroll',
						}}
					>
						<ActivityTreeView
							tree={filteredActivityTree}
							rootItems={filteredRootActivities}
							makeIsExpanded={expanded => id =>
								inputValue.length > 0 || someChildIsSelected(id) || expanded.includes(id)}
							listProps={getListboxProps()}
							onRowClick={node => (isTagSelected(node.id) ? onRemoveTag(node.id) : onAddTag(node))}
							getRowProps={(node, index) => getOptionProps({ option: node, index })}
							rowContent={({ id, color, name }) => (
								<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
									<Checkbox
										size="small"
										checked={isTagSelected(id)}
										name={'treeViewItemCheckbox'}
										icon={<RadioButtonUncheckedIcon sx={{ fontSize: 18 }} />}
										checkedIcon={<CheckCircleIcon color="action" sx={{ fontSize: 18 }} />}
										sx={{
											p: 0,
										}}
									/>
									<Typography color={color ?? undefined}>{name}</Typography>
								</Box>
							)}
						/>
					</Box>
				</Popper>
			)}
		</>
	)
}

interface Tag {
	id: number
	name: string
	color: string | null
}

interface Activity {
	id: number
	name: string
	color: string | null
	description: string
	childTags: {
		child: {
			id: number
			name: string
			color: string | null
		}
		parentTags: {
			parent: {
				id: number
			}
		}
	}
}

const TAGS = gql`
	query Tags {
		activities(orderBy: { allEventsCount: DESC }) {
			id
			name
			color
			description
			allEventsCount
			allEventsDuration
			childTags {
				child {
					id
					name
					color
				}
			}
			parentTags {
				parent {
					id
				}
			}
		}
	}
`
