import { IconButton } from '@mui/material'
import { MoreVertRounded as EditIcon } from '@mui/icons-material'

export default function EditButton({
	onClick,
	size = 'small',
	disabled = false,
	sx,
}: {
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
	size?: 'small' | 'medium' | 'large'
	disabled?: boolean
	sx?: any
}) {
	return (
		<IconButton
			size={'small'}
			disabled={disabled}
			onClick={onClick}
			sx={{ ...sx, opacity: disabled ? 0.3 : 1 }}
		>
			<EditIcon fontSize={size} />
		</IconButton>
	)
}
