import { DateTime } from 'luxon'

import { generateDurationString } from 'utils'

import { Box, Stack, Switch, Typography } from '@mui/material'

import Caption from './Caption'
import TextInput from './TextInput'
import TagArea, { Tag } from './TagArea'

export interface Event {
	startTime: DateTime
	endTime: DateTime | null
	duration: number | null
	description: string
	tags: Tag[]
}

export default function EventDetail({
	event,
	isEditing,
	isTagSelected,
	onAddTag,
	onRemoveTag,
	onDescriptionChange,
	onStartTimeChange,
	onEndTimeChange,
}: {
	event: Event
	isEditing: boolean
	isTagSelected: (id: number) => boolean
	onAddTag: (tag: Tag) => void
	onRemoveTag: (id: number) => void
	onDescriptionChange: (description: string) => void
	onStartTimeChange: (startTime: DateTime) => void
	onEndTimeChange: (endTime: DateTime | null) => void
}) {
	return (
		<Box>
			<TagArea
				label="tags"
				selectedTags={event.tags}
				isTagSelected={isTagSelected}
				onAddTag={onAddTag}
				onRemoveTag={onRemoveTag}
				isEditing={isEditing}
			/>

			<TimeDisplay startTime={event.startTime} endTime={event.endTime} duration={event.duration} />
			{isEditing && (
				<TimeInput
					startTime={event.startTime}
					endTime={event.endTime}
					onEndTimeChange={onEndTimeChange}
					onStartTimeChange={onStartTimeChange}
				/>
			)}

			<Notes isEditing={isEditing} notes={event.description} onChange={onDescriptionChange} />
		</Box>
	)
}

function TimeDisplay({
	startTime,
	endTime,
	duration,
}: {
	startTime: DateTime
	endTime: DateTime | null
	duration: number | null
}) {
	const isInterval = !!endTime

	return (
		<>
			<Box sx={{ display: 'flex', textAlign: 'center', my: 3 }}>
				<Box flex={1}>
					<Typography variant="h4">{startTime.toLocaleString(DateTime.TIME_SIMPLE)}</Typography>
					<Typography color={theme => theme.palette.text.secondary}>
						{startTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
					</Typography>
				</Box>
				{isInterval && (
					<>
						<Typography variant="h4">-</Typography>
						<Box flex={1}>
							<Typography variant="h4">{endTime.toLocaleString(DateTime.TIME_SIMPLE)}</Typography>
							<Typography color={theme => theme.palette.text.secondary}>
								{endTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
							</Typography>
						</Box>
					</>
				)}
			</Box>
			<Box textAlign={'center'} sx={{ visibility: isInterval ? 'visible' : 'hidden' }}>
				<Caption>duration</Caption>
				<Typography variant="h4">
					{generateDurationString(duration || 0, ['days', 'hours', 'minutes', 'seconds'])}
				</Typography>
			</Box>
		</>
	)
}

function TimeInput({
	startTime,
	endTime,
	onStartTimeChange,
	onEndTimeChange,
}: {
	startTime: DateTime
	endTime: DateTime | null
	onStartTimeChange: (startTime: DateTime) => void
	onEndTimeChange: (endTime: DateTime | null) => void
}) {
	return (
		<>
			<Stack direction={'row'} alignItems={'center'}>
				<Switch
					checked={!!endTime}
					onChange={e => onEndTimeChange(e.target.checked ? startTime : null)}
				/>
				<Typography variant="body2">show end time</Typography>
			</Stack>
			<Stack
				marginBottom={2}
				direction="row"
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<Caption>started</Caption>
				<TextInput
					type={'datetime-local'}
					value={toTimeInputFormat(startTime) || ''}
					onChange={startTime => onStartTimeChange(DateTime.fromISO(startTime))}
				/>
			</Stack>
			<Stack
				direction="row"
				alignItems={'center'}
				justifyContent={'space-between'}
				visibility={!!endTime ? 'visible' : 'hidden'}
			>
				<Caption>ended</Caption>
				<TextInput
					type={'datetime-local'}
					value={endTime ? toTimeInputFormat(endTime) || '' : toTimeInputFormat(startTime) || ''}
					onChange={endTime => onEndTimeChange(DateTime.fromISO(endTime))}
				/>
			</Stack>
		</>
	)
}

function Notes({
	isEditing,
	notes,
	onChange,
}: {
	isEditing: boolean
	notes: string
	onChange: (description: string) => void
}) {
	return (
		<>
			<Caption>notes</Caption>
			{isEditing ? (
				<TextInput value={notes} fullWidth multiline onUpdate={onChange} />
			) : (
				<Typography>{notes}</Typography>
			)}
		</>
	)
}

function toTimeInputFormat(dateTime: DateTime) {
	return dateTime.startOf('minute').toISO({ includeOffset: false })
}
