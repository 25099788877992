import { forwardRef, useLayoutEffect, useRef } from 'react'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { generateTimeWithDurationString } from 'utils'

import { Box, Paper, Typography } from '@mui/material'
import { Loading } from 'components'

const TOTAL_HEIGHT = 600
const SECONDS_IN_A_DAY = 86400

const LEFT_SECTION_WIDTH = 0

const ANNOTATION_MIN_HEIGHT = 15
const ANNOTATION_LEFT_PX_OFFSET = 60
const ANNOTATION_WIDTH_PERCENTAGE = 0.8

const hours = Array(24)
	.fill(0)
	.map((_, i) => i)

let previousDaysCount = 0

export default function DayViewPage() {
	const {
		data: { events = [], eventAggregate: { aggregate: { count = 0 } = {} } = {} } = {},
		loading,
		fetchMore,
	} = useQuery(DAY_VIEW_SUMMARY)

	const days: { date: string; events: any[] }[] = []
	let currentDate: string | undefined
	for (const event of events) {
		const eventDate = toShortDate(event.startTime)
		if (eventDate !== currentDate) {
			currentDate = eventDate
			days.push({
				date: currentDate,
				events: [],
			})
		}
		days[days.length - 1].events.push(event)
	}

	const [sentry] = useInfiniteScroll({
		loading,
		hasNextPage: events.length < count,
		onLoadMore: () =>
			fetchMore({
				variables: { where: { startTime: { _lt: events[events.length - 1].startTime } } },
			}),
		rootMargin: '0px 0px 400px 0px',
	})

	// handle scrolling when list changes
	const currentDayRef = useRef<HTMLDivElement>(null)
	useLayoutEffect(() => {
		currentDayRef.current?.scrollIntoView()
	}, [loading])

	useLayoutEffect(() => {
		if (!days.length) {
			window.scrollBy(0, (days.length - previousDaysCount) * TOTAL_HEIGHT)
		}
		previousDaysCount = days.length
	}, [days.length])

	return (
		<Loading loading={loading}>
			{days.reverse().map((day, i) => (
				<TimelineDay
					day={day}
					key={day.date}
					ref={i === 0 ? sentry : i === days.length - 1 ? currentDayRef : undefined}
				/>
			))}
		</Loading>
	)
}

const TimelineDay = forwardRef(({ day }: { day: any }, ref) => {
	return (
		<Box sx={{ position: 'relative', height: TOTAL_HEIGHT, border: 'solid white 0px' }} ref={ref}>
			{hours.map(hour => (
				<TimelineHour key={`${day}-${hour}`} day={day} hour={hour} />
			))}
			{day.events.map(event => (
				<TimelineAnnotation key={event.id} event={event} />
			))}
		</Box>
	)
})

function TimelineAnnotation({ event }) {
	const navigate = useNavigate()
	return (
		<Paper
			key={event.id}
			onClick={() => navigate(`/event/${event.id}`)}
			sx={{
				position: 'absolute',
				top: calcSecondsThroughDay(DateTime.fromISO(event.startTime)),
				left: ANNOTATION_LEFT_PX_OFFSET + LEFT_SECTION_WIDTH,
				height: event.endTime
					? Math.max(
							calcDurationInSeconds(
								DateTime.fromISO(event.startTime),
								DateTime.fromISO(event.endTime)
							),
							ANNOTATION_MIN_HEIGHT
					  )
					: undefined,
				width: ANNOTATION_WIDTH_PERCENTAGE,
				padding: 0,
				borderRadius: 2,
				display: 'flex',
				justifyContent: 'space-between',
				gap: 1,
			}}
		>
			{event.tags.map(tag => (
				<Typography key={tag.activity.id} color={tag.activity.color} variant="dayView">
					{tag.activity.name}
				</Typography>
			))}
			<Typography variant="dayView">{event.description}</Typography>
			<Typography variant="dayView">{generateTimeWithDurationString(event)}</Typography>
		</Paper>
	)
}

function TimelineHour({ hour, day }) {
	return (
		<Box sx={{ ml: `${LEFT_SECTION_WIDTH}px`, display: 'flex' }}>
			<Typography
				sx={{ width: 50, position: 'relative', top: -8, textAlign: 'right', pr: 2 }}
				color="gray"
				variant="dayView"
			>
				{hour % 3 === 0
					? DateTime.fromObject({ hour: hour }).toLocaleString({ hour: 'numeric' })
					: '-'}
			</Typography>
			<Box sx={{ borderTop: 'solid #333333 1px', width: 1, height: TOTAL_HEIGHT / 24 }} />
			{hour === 0 && (
				<Typography sx={{ position: 'absolute', left: '45%', top: -12 }}>{day.date}</Typography>
			)}
		</Box>
	)
}

const toShortDate = (iso: string) => DateTime.fromISO(iso).toLocaleString(DateTime.DATE_SHORT)

function calcSecondsThroughDay(dateTime: DateTime) {
	const today = DateTime.fromSeconds(dateTime.toSeconds()).startOf('day')
	return (dateTime.diff(today, 'seconds').seconds / SECONDS_IN_A_DAY) * TOTAL_HEIGHT
}

function calcDurationInSeconds(startTime: DateTime, endTime: DateTime) {
	return (endTime.diff(startTime, 'seconds').seconds / SECONDS_IN_A_DAY) * TOTAL_HEIGHT
}

const DAY_VIEW_SUMMARY = gql`
	query DayView($limit: Int = 20, $where: EventBoolExp) {
		events(limit: $limit, orderBy: { startTime: DESC }, where: $where) {
			id
			startTime
			endTime
			duration
			description
			tags {
				activity {
					id
					name
					color
				}
			}
		}
		eventAggregate {
			aggregate {
				count
			}
		}
	}
`
