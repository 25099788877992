import { Box, ListItem, ListItemText, ListItemButton } from '@mui/material'
import DeleteButton from './DeleteButton'
import TextInput from './TextInput'

export default function ({
	primary,
	color,
	secondary = '',
	secondaryAction,
	primaryAction,
	divider = false,
	onClick,
	onMouseEnter,
	buttonStyle,
	listItemStyle,
	children,
}: {
	primary?: React.ReactNode
	color?: string
	secondaryAction?: React.ReactNode
	primaryAction?: React.ReactNode
	secondary?: string
	divider?: boolean
	onClick?: () => void
	onMouseEnter?: () => void
	buttonStyle?: object
	listItemStyle?: object
	children?: React.ReactNode
}) {
	return (
		<ListItem
			disablePadding
			divider={divider}
			onMouseEnter={onMouseEnter}
			sx={listItemStyle}
			alignItems="flex-start"
		>
			{primaryAction}
			{children || (
				<ListItemButton
					onClick={onClick}
					sx={{
						flex: 1,
						...buttonStyle,
					}}
				>
					<ListItemText
						primary={primary}
						secondary={secondary}
						primaryTypographyProps={{
							sx: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
							color,
						}}
					/>
				</ListItemButton>
			)}
			<Box sx={{ p: 1 }}>{secondaryAction}</Box>
		</ListItem>
	)
}

const formatTime = (timestamp: string) => {
	const dt = new Date(timestamp)
	return `${dt.toDateString()} | ${dt.toLocaleTimeString()}`
}
