import { useEffect, useState } from 'react'

export default function useDuration(startTime: string) {
	const [elapsedTime, setElapsedTime] = useState(0)

	if (!startTime && elapsedTime) {
		setElapsedTime(0)
	}

	useEffect(() => {
		const interval =
			startTime && setInterval(() => setElapsedTime(Date.now() - Date.parse(startTime)))
		return () => clearInterval(interval)
	}, [startTime])

	return elapsedTime
}
