import { useState } from 'react'

import TextInput from './TextInput'

export default function ColorPicker({
	value,
	onChange,
}: {
	value: string
	onChange: (color: string) => void
}) {
	const [color, setColor] = useState(value)

	return (
		<TextInput
			type="color"
			fullWidth
			sx={{
				bgcolor: color,
			}}
			value={color}
			onUpdate={onChange}
			onChange={setColor}
		/>
	)
}
