import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { actions, useDuration } from 'hooks'
import { generateDurationString } from 'utils'
import { DateTime, DurationLikeObject } from 'luxon'

import { Box, Button, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ParentSize } from '@visx/responsive'
import { CheckRounded as CheckIcon } from '@mui/icons-material'

import {
	ActivityGoal,
	AddButton,
	BarChart,
	CalendarHeatMap,
	ChipStack,
	Caption,
	ColorPicker,
	DeleteConfirmationDialog,
	ActivityMenu,
	PieChart,
	StartStopButton,
	ShowMoreLessButton,
	TagArea,
	TextInput,
} from 'components'

const CHART_HEIGHT = 150

export default function ActivityHeader({
	activity,
	isEditing,
	setIsEditing,
	eventsByDateStep,
}: {
	activity: any
	isEditing: boolean
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
	eventsByDateStep: keyof DurationLikeObject
}) {
	const { deleteActivity } = actions.useDeleteActivity()

	const navigate = useNavigate()

	const [showMore, setShowMore] = useState(false)
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

	return (
		<Stack spacing={1} sx={{ position: 'relative' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
				{isEditing ? (
					<IconButton size="small" onClick={() => setIsEditing(false)}>
						<CheckIcon color="success" fontSize="medium" />
					</IconButton>
				) : (
					<ActivityMenu setIsEditing={setIsEditing} setIsDeleteDialogOpen={setIsDeleteDialogOpen} />
				)}
			</Box>
			<Box px={3}>
				<ParentTags activityId={activity.id} tags={activity.parentTags} isEditing={isEditing} />
				<Title
					activityId={activity.id}
					name={activity.name}
					color={activity.color}
					isEditing={isEditing}
				/>
				<ChildTags activityId={activity.id} tags={activity.childTags} isEditing={isEditing} />
			</Box>
			{/* <TextInput
				placeholder="description"
				value={activity.description}
				onUpdate={description => updateActivity({ id: activity.id, description })}
				sx={{
					'.MuiInputBase-input': { fontSize: 14, lineHeight: 1.2, color: 'text.secondary' },
				}}
				multiline
				fullWidth
			/> */}
			<div>
				<ActivitySummary activity={activity} />
				<ActivityControls activity={activity} />
				{/* <PieChart
					radius={75}
					data={activity.childTags.map(({ child }) => ({
						label: child.name,
						value: child.allEventsCount / activity.allEventsCount,
						color: child.color,
					}))}
				/> */}
				{/* <ViolinChart /> */}
				<ParentSize>
					{({ width }) => (
						<BarChart
							width={width}
							height={CHART_HEIGHT}
							data={activity.eventsByDate}
							step={eventsByDateStep}
						/>
					)}
				</ParentSize>
				{/* <CalendarHeatMap
					data={activity.eventsGroupedByDay}
					// from={DateTime.now()}
					from={new Date(2023, 0, 1)}
					to={new Date(2024, 0, 1)}
				/> */}
				{/* <ActivityGoals goals={activity.goals} activity={activity} isEditing={isEditing} /> */}
				<Collapse in={showMore}>
					<Box sx={{ display: 'flex', textAlign: 'center', flexWrap: 'wrap' }}>
						<TimeStat title={'duration today'} time={activity.totalDurationToday} />
						<TimeStat title={'duration this week'} time={activity.totalDurationWeek} />
						<TimeSince time={getLastEventTime(activity)} />
					</Box>
				</Collapse>
				<Divider>
					<ShowMoreLessButton open={showMore} onClick={() => setShowMore(showMore => !showMore)} />
				</Divider>
			</div>
			<DeleteConfirmationDialog
				open={isDeleteDialogOpen}
				setOpen={setIsDeleteDialogOpen}
				onClick={() => {
					navigate(-1)
					deleteActivity(activity.id)
				}}
			/>
		</Stack>
	)
}

function Title({ activityId, name, color, isEditing }) {
	const { updateActivity } = actions.useUpdateActivity()

	return (
		<Box sx={{ display: 'flex', alignItems: 'stretch', my: 1, gap: 4 }}>
			{isEditing ? (
				<>
					<Box sx={{ flex: 5 }}>
						<Caption>name</Caption>
						<TextInput
							placeholder="name"
							value={name}
							onUpdate={name => updateActivity({ id: activityId, name })}
							multiline
							inputProps={{
								sx: { fontSize: 24, color },
							}}
						/>
					</Box>
					<Box sx={{ flex: 1 }}>
						<Caption>color</Caption>
						<ColorPicker
							value={color}
							onChange={color => updateActivity({ id: activityId, color })}
						/>
					</Box>
				</>
			) : (
				<Typography variant={'h3'} color={color}>
					{name}
				</Typography>
			)}
		</Box>
	)
}

function ParentTags({ activityId, tags, isEditing }) {
	const { addParentTagsToActivity } = actions.useAddTagsToActivity()
	const { removeTagFromActivity } = actions.useRemoveTagFromActivity()

	return (
		<ChipStack
			tags={tags.map(({ parent }) => parent)}
			tagIdsToExclude={tags.map(({ parent }) => parent.id).concat(activityId)}
			onAddTags={
				isEditing
					? tags =>
							addParentTagsToActivity(
								activityId,
								tags.map(tag => tag.id)
							)
					: undefined
			}
			onDeleteTag={
				isEditing
					? tagId => removeTagFromActivity({ parentActivityId: tagId, childActivityId: activityId })
					: undefined
			}
		/>
	)
}

function ChildTags({ activityId, tags, isEditing }) {
	const { addChildTagsToActivity } = actions.useAddTagsToActivity()
	const { removeTagFromActivity } = actions.useRemoveTagFromActivity()

	return (
		<ChipStack
			tags={tags.map(({ child }) => child)}
			tagIdsToExclude={tags.map(({ child }) => child.id).concat(activityId)}
			onAddTags={
				isEditing
					? tags =>
							addChildTagsToActivity(
								activityId,
								tags.map(tag => tag.id)
							)
					: undefined
			}
			onDeleteTag={
				isEditing
					? tagId => removeTagFromActivity({ parentActivityId: activityId, childActivityId: tagId })
					: undefined
			}
		/>
	)
}

function ActivityGoals({ goals, activity, isEditing }) {
	const { createGoal } = actions.useGoals()

	return (
		<Box sx={{ p: 1 }}>
			<Stack spacing={1} direction={'row'} alignItems={'center'}>
				{/* <Typography>Goals</Typography> */}
				{isEditing && (
					<Button onClick={() => createGoal({ duration: 60 * 60, interval: 'week' }, activity.id)}>
						add goal
					</Button>
				)}
			</Stack>
			{goals.map(goal => (
				<ActivityGoal key={goal.id} goal={goal} activity={activity} isEditing={isEditing} />
			))}
		</Box>
	)
}

function TimeSince({ time }) {
	const timeSinceLastEvent = useDuration(time)
	return <TimeStat time={timeSinceLastEvent / 1000} title="time since last event" />
}

function TimeStat({ time, title }) {
	return (
		<Box sx={{ flex: 1 }}>
			<Typography variant={'h5'}>{generateDurationString(time)}</Typography>
			<Typography variant={'body2'} color="text.secondary">
				{title}
			</Typography>
		</Box>
	)
}

function ActivitySummary({ activity }) {
	return (
		<Box sx={{ display: 'flex', textAlign: 'center', pt: 2 }}>
			<Box sx={{ flex: 1 }}>
				<Typography variant="h4">{activity.allEventsCount}</Typography>
				<Typography variant="body2" color="text.secondary">
					events
				</Typography>
			</Box>
			{activity.allEventsDuration && (
				<Box sx={{ flex: 1, overflow: 'hidden' }}>
					<Typography
						variant="h4"
						sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
					>
						{generateDurationString(activity.allEventsDuration)}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						total duration
					</Typography>
				</Box>
			)}
		</Box>
	)
}

function ActivityControls({
	activity,
}: {
	activity: { id: number; name: string; currentEvent: { id: number; startTime: string } }
}) {
	const { createEvent, createEventStatus } = actions.useCreateEvent()
	const { startActivity, startActivityStatus } = actions.useStartActivity()
	const { stopActivity, stopActivityStatus } = actions.useStopActivity()

	const currentEventDuration = useDuration(activity.currentEvent?.startTime)

	return (
		<>
			<Box sx={{ textAlign: 'center' }}>
				<AddButton
					onClick={() => createEvent({ tags: [{ id: activity.id, name: activity.name }] })}
					size="large"
					disabled={createEventStatus.loading}
				/>
				<StartStopButton
					condition={!!activity.currentEvent}
					onStartClick={() => startActivity({ tags: [activity] }, activity)}
					onStopClick={() =>
						stopActivity(activity.currentEvent?.id, {
							totalDurationTodayArgs: {
								start_time: DateTime.fromObject({ ordinal: DateTime.now().ordinal }).toISO(),
							},
							totalDurationWeekArgs: {
								start_time: DateTime.fromObject({ weekNumber: DateTime.now().weekNumber }).toISO(),
							},
							totalDurationMonthArgs: {
								start_time: DateTime.fromObject({ month: DateTime.now().month }).toISO(),
							},
							totalDurationYearArgs: {
								start_time: DateTime.fromObject({ year: DateTime.now().year }).toISO(),
							},
						})
					}
					size="large"
					disabled={startActivityStatus.loading || stopActivityStatus.loading}
				/>
			</Box>
			{activity.currentEvent && (
				<Box textAlign={'center'}>
					<Typography variant="h4">
						{currentEventDuration < 1000
							? '0s'
							: generateDurationString(currentEventDuration / 1000)}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						current event
					</Typography>
				</Box>
			)}
		</>
	)
}

function getLastEventTime(activity) {
	const event = activity.currentEvent ? activity.events[1] : activity.events[0]
	return event?.endTime || event?.startTime
}
