import { Box, CircularProgress } from '@mui/material'

export default function Loading({ loading, children }) {
	return (
		<>
			{loading ? (
				<Box
					sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
				>
					<CircularProgress sx={{ color: 'white' }} />
				</Box>
			) : (
				children
			)}
		</>
	)
}
