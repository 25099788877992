import { useQuery, gql } from '@apollo/client'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { EventList, Loading } from 'components'

export default function HomePage() {
	const {
		data: { events = [], eventAggregate: { aggregate: { count = 0 } = {} } = {} } = {},
		loading,
		fetchMore,
	} = useQuery(GET_SUMMARY, {
		variables: {
			activitiesByDateArgs: {},
		},
	})

	// set up ref to attatch to last element so a query can be made
	// when that element almost comes into view.
	const [sentry] = useInfiniteScroll({
		loading,
		hasNextPage: events.length < count,
		onLoadMore: () =>
			fetchMore({
				variables: { where: { startTime: { _lt: events[events.length - 1].startTime } } },
			}),
		rootMargin: '0px 0px 400px 0px',
	})

	return (
		<Loading loading={loading}>
			<EventList events={events} />
			<div ref={sentry} />
		</Loading>
	)
}

export const GET_SUMMARY = gql`
	query Summary(
		$limit: Int = 20
		$where: EventBoolExp
		$totalDurationArgs: totalDuration_activity_args! = {}
		$activitiesByDateArgs: activitiesByDateArgs! = {}
	) {
		eventAggregate {
			aggregate {
				count
			}
		}
		events(orderBy: { startTime: DESC }, limit: $limit, where: $where) {
			id
			startTime
			endTime
			duration
			description
			tags {
				activity {
					id
					name
					color
				}
			}
			parentEvents(orderBy: { startTime: ASC }) {
				id
				description
				tags {
					activity {
						id
						name
						color
					}
				}
			}
			childEvents(orderBy: { startTime: ASC }) {
				id
				description
				startTime
				endTime
				duration
				tags {
					activity {
						id
						name
						color
					}
				}
			}
		}
	}
`
