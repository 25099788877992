import { useMemo } from 'react'
import { Group } from '@visx/group'
import { ParentSize } from '@visx/responsive'

import { timeDays, timeWeek, timeMonths, timeMonth, timeYear } from 'd3-time'
import { timeFormat } from 'd3-time-format'
import { useMediaQuery, useTheme } from '@mui/material'

const monthName = timeFormat('%b')

export default function CalendarHeatMap({ data, from, to }: { data; from: Date; to: Date }) {
	const yearMonths = timeMonths(from, to)

	const dates: Set<string> = useMemo(
		() => data.reduce((dates, { date }) => dates.add(new Date(date).toDateString()), new Set()),
		[data]
	)

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const cellGap = isMobile ? 2 : 4
	const cellBorderRadius = isMobile ? 4 : 8

	return (
		<ParentSize>
			{({ width }) => (
				<svg width={width} height={cellSize(width) * 7 + 15}>
					{yearMonths.map((month, i) => (
						<Group key={month.toDateString()} fill="none" stroke={'black'} strokeWidth={cellGap}>
							<text
								fill="rgb(73,80,87)"
								stroke="none"
								fontSize={10}
								x={timeWeek.count(timeYear(month), month) * cellSize(width) + cellGap}
								y={10}
							>
								{monthName(month)}
							</text>
							{timeDays(month, new Date(month.getFullYear(), month.getMonth() + 1, 1)).map(day => (
								<rect
									fill={getColor(dates, day.toDateString())}
									key={day.toString()}
									width={cellSize(width)}
									height={cellSize(width)}
									x={timeWeek.count(timeYear(day), day) * cellSize(width)}
									y={day.getDay() * cellSize(width) + 15}
									rx={cellBorderRadius}
								/>
							))}
						</Group>
					))}

					{/* <Group fill="none" stroke={'#555'}>
						{yearMonths.map(firstOfMonth => (
							<path d={pathMonth(firstOfMonth, cellSize(width))} key={firstOfMonth.toString()} />
						))}
					</Group> */}
				</svg>
			)}
		</ParentSize>
	)
}

function getColor(dates: Set<string>, day: string) {
	const today = new Date()
	if (today.toDateString() === day) {
		return 'dodgerblue'
	}

	if (dates.has(day)) {
		return 'green'
	}

	return '#111'
}

function pathMonth(t0: Date, cellSize: number) {
	const d0 = t0.getDay()
	const w0 = timeWeek.count(timeYear(t0), t0)
	const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0)
	const d1 = t1.getDay()
	const w1 = timeWeek.count(timeYear(t1), t1)

	return `
    M${(w0 + 1) * cellSize},${d0 * cellSize}
    H${w0 * cellSize} V${7 * cellSize}
    H${w1 * cellSize} V${(d1 + 1) * cellSize}
    H${(w1 + 1) * cellSize} V0
    H${(w0 + 1) * cellSize}Z
  `
}

const cellSize = (totalWidth: number) => Math.floor(totalWidth / 52)
