import { useMutation, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { mergeSortedLists } from 'utils'

import { Event, createGqlEvent } from './useCreateEvent'

export default function useStartActivity() {
	async function startActivity(event: Event, activity) {
		return startActivityMutation({
			variables: {
				object: {
					id: activity.id,
					name: activity.name,
					currentEvent: {
						data: await createGqlEvent(event),
					},
				},
				onConflict: {
					constraint: 'activities_pkey',
					updateColumns: 'currentEventId',
				},
			},
			update(cache, { data: { insertActivityOne: activity } }) {
				// add to every tag
				for (const tag of activity.currentEvent.tags) {
					cache.modify({
						id: cache.identify(tag.activity),
						fields: {
							allEvents(existing, { toReference }) {
								return [toReference(activity.currentEvent), ...existing]
							},
						},
					})
				}

				// add to home feed
				cache.modify({
					fields: {
						events(existing, { toReference }) {
							return [toReference(activity.currentEvent), ...existing]
						},
					},
				})
			},
		})
	}

	const [startActivityMutation, startActivityStatus] = useMutation(gql`
		mutation InsertActivityOne(
			$object: ActivityInsertInput!
			$onConflict: ActivityOnConflict
			$eventLimit: Int = 10
			$eventWhere: EventBoolExp
		) {
			insertActivityOne(object: $object, onConflict: $onConflict) {
				id
				currentEvent {
					id
					startTime
					endTime
					duration
					description
					tags {
						activity {
							id
							allEventsCount
							allEventsDuration
						}
					}
					parentEvents(orderBy: { startTime: ASC }) {
						id
						description
						tags {
							activity {
								id
								name
								color
							}
						}
					}
					childEventsCount
					childEvents(orderBy: { startTime: ASC }) {
						id
						description
						startTime
						endTime
						duration
						tags {
							activity {
								id
								name
								color
							}
						}
					}
				}
			}
		}
	`)

	return {
		startActivity,
		startActivityStatus,
	}
}
