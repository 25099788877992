import { useEffect, useState } from 'react'

import { Menu as MuiMenu } from '@mui/material'

export default function Menu({
	children,
	menuAnchor,
	setMenuAnchor,
}: {
	children: JSX.Element[]
	menuAnchor: HTMLElement | null
	setMenuAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}) {
	const isMenuOpen = !!menuAnchor

	function handleMenuClose() {
		setMenuAnchor(null)
	}

	// close the menu if the page is scrolled
	useEffect(() => {
		if (isMenuOpen) {
			document.addEventListener('scroll', handleMenuClose, false)
		}

		return () => {
			document.removeEventListener('scroll', handleMenuClose, false)
		}
	}, [isMenuOpen])

	return (
		<MuiMenu disableScrollLock anchorEl={menuAnchor} open={isMenuOpen} onClose={handleMenuClose}>
			{children}
		</MuiMenu>
	)
}
