import { IconButton } from '@mui/material'
import { AddRounded as AddIcon } from '@mui/icons-material'

export default function AddButton({
	onClick,
	size = 'small',
	disabled = false,
}: {
	onClick: () => void
	size?: 'small' | 'medium' | 'large'
	disabled?: boolean
}) {
	return (
		<IconButton
			size={'small'}
			disabled={disabled}
			onClick={onClick}
			sx={{ opacity: disabled ? 0.3 : 1 }}
		>
			<AddIcon color={'success'} fontSize={size} />
		</IconButton>
	)
}
