import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'

import { actions } from 'hooks'
import { generateTimeWithDurationString } from 'utils'

import { Box, Chip, Collapse, List, ListSubheader, Stack, Typography } from '@mui/material'

import DeleteButtonWithInlineConfirmation from './DeleteButtonWithInlineConfirmation'
import ListItem from './ListItem'
import ShowMoreLessButton from './ShowMoreLessButton'

export default function EventList({
	events,
	isCurrentEvent,
	isEditing = false,
}: {
	events: any[]
	isCurrentEvent?: (eventId: string) => boolean
	isEditing?: boolean
}) {
	const eventsGroupedByDay = useMemo(() => {
		const timeline: any[] = []
		let mostRecentDate

		const seenParentEvents = new Set<number>()

		for (const event of events) {
			const eventDate = toShortDate(event.startTime)
			if (eventDate !== mostRecentDate) {
				mostRecentDate = eventDate
				timeline.push(
					<ListSubheader
						key={event.startTime + event.id}
						sx={{ backgroundColor: theme => theme.palette.background.default }}
					>
						<Typography textAlign={'center'}>
							{DateTime.fromISO(event.startTime).toLocaleString({
								weekday: 'long',
								day: 'numeric',
								month: 'long',
							})}
						</Typography>
					</ListSubheader>
				)
			}

			if (!seenParentEvents.has(event.id)) {
				timeline.push(
					<NestedEventRow key={event.id} event={event} seenParentEvents={seenParentEvents} />
				)
			}
		}

		return timeline
	}, [events])

	return <List>{eventsGroupedByDay}</List>
}

export function NestedEventRow({ event, seenParentEvents, isEditing = false }) {
	return (
		<>
			<Box
				sx={{
					backgroundColor: theme => theme.palette.background.default,
					zIndex: 2,
				}}
			>
				{event.parentEvents.map(
					(event, _, parentEvents) =>
						!seenParentEvents.has(event.id) &&
						seenParentEvents.add(event.id) && (
							<ParentEvent key={event.id} event={event} parentEvents={parentEvents} />
						)
				)}
			</Box>
			<Box sx={{ display: 'flex' }}>
				{event.parentEvents.map(event => (
					<VerticalLine key={event.id} color={event.tags[0]?.activity.color} />
				))}
				<EventRow event={event} isEditing={isEditing} />
			</Box>
		</>
	)
}

function ParentEvent({ event, parentEvents }) {
	const navigate = useNavigate()

	return (
		<Box sx={{ display: 'flex' }}>
			{parentEvents.map(event => (
				<VerticalLine key={event.id} color={event.tags[0]?.activity.color} />
			))}
			<Box
				onClick={() => navigate(`/event/${event.id}`)}
				sx={{
					flex: 1,
					px: 4,
					py: 2,
					borderBottomRightRadius: 12,
					borderTopRightRadius: 12,
					backgroundColor: theme => theme.palette.background.paper,
				}}
			>
				<Typography variant="h6">{event.description}</Typography>
				{event.tags.map(({ activity }) => (
					<Chip sx={{ color: activity.color }} key={activity.id} label={activity.name} />
				))}
			</Box>
		</Box>
	)
}

function EventRow({ event, isEditing }: { event: any; isEditing: boolean }) {
	const { deleteEvent } = actions.useDeleteEvent()
	const navigate = useNavigate()

	const [showMore, setShowMore] = useState(false)

	return (
		<ListItem
			onClick={() => navigate(`/event/${event.id}`)}
			primary={<EventItem event={event} showMore={showMore} />}
			// secondary={DateTime.fromISO(event.startTime).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
			secondaryAction={
				isEditing ? (
					<DeleteButtonWithInlineConfirmation onClick={() => deleteEvent(event.id)} />
				) : event.childEventsCount ? (
					<ShowMoreLessButton open={showMore} onClick={() => setShowMore(showMore => !showMore)} />
				) : undefined
			}
		/>
	)
}

function EventItem({ event, showMore = false }: { event: any; showMore?: boolean }) {
	const { id: activityId } = useParams()

	return (
		<Stack direction="column" justifyContent={'space-between'}>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: 1,
				}}
			>
				{event.tags
					// ?.filter(({ activity }) => activity.id != activityId)
					.map(({ activity }) => (
						<Chip sx={{ color: activity.color }} key={activity.id} label={activity.name} />
					))}
			</Box>
			{event.description && (
				<Typography sx={{ whiteSpace: 'normal' }}>{event.description}</Typography>
			)}
			<Typography variant="body2" color={'text.secondary'}>
				{generateTimeWithDurationString(event)}
			</Typography>
			<Collapse in={showMore}>
				{event.childEvents?.map(childEvent => (
					<Box key={childEvent.id} sx={{ display: 'flex' }}>
						<VerticalLine color={event.tags[0]?.activity.color} side="left" />
						<EventItem event={childEvent} />
					</Box>
				))}
			</Collapse>
		</Stack>
	)
}

function VerticalLine({
	color = 'white',
	side = 'right',
}: {
	color: string
	side?: 'left' | 'right'
}) {
	return (
		<Box
			sx={{
				flex: '0 0 15px',
				[side === 'right' ? 'borderRight' : 'borderLeft']: `solid ${color ?? 'white'} 1.5px`,
			}}
		/>
	)
}
const toShortDate = (iso: string) => DateTime.fromISO(iso).toLocaleString(DateTime.DATE_SHORT)
