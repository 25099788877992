import { useState } from 'react'

export default function useTagsState(originalTags: Tag[]) {
	const [tags, setTags] = useState<Tag[]>(originalTags)

	const isTagSelected = (id: number) => tags.some(tag => tag.id === id)
	const selectTag = (tag: Tag) => setTags([...tags, tag])
	const deselectTag = (id: number) => setTags(tags.filter(existing => existing.id !== id))

	function reset() {
		setTags(originalTags)
	}

	return {
		tags,
		isTagSelected,
		selectTag,
		deselectTag,
		reset,
	}
}

export interface Tag {
	id: number
	name: string
	color: string | null
}
