import { useMutation, gql } from '@apollo/client'

export default function useItems() {
	const [insertItemMutation] = useMutation(
		gql`
			mutation InsertItem($object: ItemInsertInput!) {
				insertItemOne(object: $object) {
					id
					name
					listItems {
						list {
							id
							listItems {
								item {
									id
									name
								}
							}
						}
					}
				}
			}
		`,
		{
			refetchQueries: ['List'],
		}
	)

	const [updateItemMutation] = useMutation(
		gql`
			mutation UpdateItemByPk($pkColumns: ItemPkColumnsInput!, $_set: ItemSetInput!) {
				updateItemByPk(pkColumns: $pkColumns, _set: $_set) {
					id
					name
					completedAt
				}
			}
		`,
		{
			refetchQueries: ['List'],
		}
	)

	const [deleteItemMutation] = useMutation(
		gql`
			mutation DeleteItemByPk($id: Int!) {
				deleteItemByPk(id: $id) {
					id
				}
			}
		`,
		{
			update(
				cache,
				{
					data: {
						deleteItemByPk: { id },
					},
				}
			) {
				cache.evict({ id: `Item:${id}` })
			},
		}
	)

	function createItem(item = {}, list) {
		insertItemMutation({
			variables: {
				object: {
					...item,
					listItems: {
						data: {
							list: {
								data: { id: list.id, name: list.name },
								onConflict: { constraint: 'list_pkey', updateColumns: 'name' },
							},
						},
					},
				},
			},
		})
	}

	function updateItem({ id, ...fields }) {
		updateItemMutation({
			variables: {
				pkColumns: { id },
				_set: fields,
			},
		})
	}

	function deleteItem(id) {
		deleteItemMutation({
			variables: { id },
		})
	}

	return {
		createItem,
		updateItem,
		deleteItem,
	}
}
