import { Group } from '@visx/group'
import { ViolinPlot, BoxPlot } from '@visx/stats'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Axis } from '@visx/axis'

const width = 500
const height = 500

const random = mulberry32(420)

const data = Array(24)
	.fill(0)
	.map((_, i) => ({ x: i, y: random() * 30 + 1 }))

const minYValue = Math.min(...data.map(({ y }) => y))
const maxYValue = Math.max(...data.map(({ y }) => y))

const xScale = scaleBand<number>({
	range: [0, 500],
	// round: true,
	domain: data.map(({ x }) => x),
	// padding: 0.4,
})

const yScale = scaleLinear<number>({
	range: [0, 500],
	round: true,
	domain: [minYValue, maxYValue],
})

export default function ViolinChart() {
	return (
		<svg width={width} height={height}>
			<Group top={0} left={50}>
				<ViolinPlot
					horizontal
					data={data}
					// stroke="white"

					fill="white"
					valueScale={yScale}
					width={100}
					value={({ x }) => x}
					count={({ y }) => y}
				/>
			</Group>
			<Axis
				rangePadding={0}
				scale={xScale}
				top={110}
				stroke="white"
				tickStroke="white"
				numTicks={12}
			/>
		</svg>
	)
}
function mulberry32(a) {
	return function () {
		var t = (a += 0x6d2b79f5)
		t = Math.imul(t ^ (t >>> 15), t | 1)
		t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
		return ((t ^ (t >>> 14)) >>> 0) / 4294967296
	}
}
