import { useNavigate } from 'react-router-dom'

import { Box, Chip, Stack, Typography } from '@mui/material'
import { AddTagDialog } from 'components'

export interface Tag {
	id: number
	name: string
	color: string
}

export default function ChipStack({
	tags,
	tagIdsToExclude = [],
	title,
	onAddTags,
	onDeleteTag,
}: {
	tags: Tag[]
	tagIdsToExclude?: number[]
	title?: string
	onAddTags?: (selectedTags: Tag[]) => void
	onDeleteTag?: (tagId: number) => void
}) {
	const navigate = useNavigate()

	return (
		<div>
			{(title || onAddTags) && (
				<Stack
					direction="row"
					alignItems={'center'}
					justifyContent={'space-between'}
					spacing={1}
					width={175}
				>
					{title && <Typography>{title}</Typography>}
					{onAddTags && (
						<AddTagDialog
							initialSelectedTagIds={tagIdsToExclude}
							onSuccess={selectedTags => onAddTags(selectedTags)}
						/>
					)}
				</Stack>
			)}
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: 1,
				}}
			>
				{tags.map(tag => (
					<Chip
						key={tag.id}
						label={tag.name}
						sx={{
							color: tag.color,
							// backgroundColor: theme => theme.palette.background.default,
							// border: `solid 1px ${tag.color || 'white'}`,
						}}
						onClick={() => navigate(`/activity/${tag.id}`)}
						onDelete={onDeleteTag ? () => onDeleteTag(tag.id) : undefined}
					/>
				))}
			</Box>
		</div>
	)
}
