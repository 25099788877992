import React, { useCallback, useEffect, useState } from 'react'
import { useSpring } from '@react-spring/web'

export default function useBoop({
	x = 0,
	y = 0,
	rotation = 0,
	scale = 1,
	timing = 150,
	config = {
		tension: 300,
		friction: 10,
	},
}: {
	x?: number
	y?: number
	rotation?: number
	scale?: number
	timing?: number
	config?: {
		tension: number
		friction: number
	}
}) {
	const [isBooped, setIsBooped] = useState(false)

	const style = useSpring({
		transform: `
			translate(${isBooped ? x : 0}px, ${isBooped ? y : 0}px)
			rotate(${isBooped ? rotation : 0}deg)
			scale(${isBooped ? scale : 1})	
		`,
		config,
	})

	useEffect(() => {
		if (!isBooped) {
			return
		}

		const timeout = setTimeout(() => setIsBooped(false), timing)

		return () => clearTimeout(timeout)
	}, [isBooped])

	const trigger = useCallback(() => {
		setIsBooped(true)
	}, [])

	return { style, trigger }
}
