import React from 'react'
import { animated, config, useTransition } from '@react-spring/web'

export default function Toggle({ toggle, A, B }) {
	const transitions = useTransition(toggle, {
		from: { opacity: 0, transform: `translate3d(0,${toggle ? 100 : -100}%,0)` },
		enter: { opacity: 1, transform: `translate3d(0,0%,0)` },
		leave: { opacity: 0, transform: `translate3d(0,${toggle ? -100 : 100}%,0)` },
		reverse: toggle,
		config: config.default,
	})

	return transitions((props, item) => (
		<animated.div style={{ position: 'absolute', ...props }}>{item ? A : B}</animated.div>
	))
}
