import { Duration, DurationLikeObject } from 'luxon'

export default function generateDurationString(
	seconds: number = 0,
	units: (keyof DurationLikeObject)[] = ['hours', 'minutes', 'seconds']
) {
	if (!seconds) {
		return '0s'
	}

	const duration = Duration.fromObject({ seconds }).shiftTo(...units)
	// ${duration.weeks ? "w'w'" : ''}
	const format = `
		${duration.days ? "d'd'" : ''}
		${duration.hours ? "h'h'" : ''}
		${duration.minutes ? "m'm'" : ''}
		${duration.seconds ? "s's'" : ''}
	`

	return duration.toFormat(format)
}
