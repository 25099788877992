import { useState, useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { actions } from 'hooks'
import { generateDurationString } from 'utils'

import { Box, Button, Typography } from '@mui/material'
import { ActivityTreeView, Loading, TextInput } from 'components'
import { useActivityTree } from 'components/ActivityTreeView'

export default function ActivitiesPage() {
	const navigate = useNavigate()
	const { createActivity } = actions.useCreateActivity()

	const [search, setSearch] = useState('')
	const [filterValue, setFilterValue] = useState(
		'all' || localStorage.getItem('filterValue') || 'all'
	)

	const { loading, data: { activities = [] } = {} } = useQuery(test, {
		variables: {
			where: getQueryVariables(filterValue),
		},
	})

	const { filteredActivityTree, filteredRootActivities } = useActivityTree({ activities, search })

	return (
		<Loading loading={loading}>
			<Button onClick={() => createActivity({ name: 'new activity' })}>create activity!</Button>
			<ListFilter
				search={search}
				setSearch={setSearch}
				filterValue={filterValue}
				setFilterValue={value => {
					setFilterValue(value)
					localStorage.setItem('filterValue', value)
				}}
			/>
			<ActivityTreeView
				tree={filteredActivityTree}
				rootItems={filteredRootActivities}
				onRowClick={activity => navigate(`/activity/${activity.id}`)}
				rowContent={({ name, color, allEventsCount, allEventsDuration, isParent }) => (
					<Box pl={2}>
						<Typography color={color ?? undefined}>{name}</Typography>
						{isParent && (
							<Typography variant="body2" color="text.secondary">
								{generateActivityListItemSecondary({ allEventsCount, allEventsDuration })}
							</Typography>
						)}
					</Box>
				)}
			/>
		</Loading>
	)
}

function ListFilter({ search, setSearch, filterValue, setFilterValue }) {
	return (
		<TextInput
			value={search}
			onChange={setSearch}
			type="search"
			placeholder="search"
			fullWidth
			sx={{ mb: 1 }}
		/>
	)
}

function getQueryVariables(filterValue: string) {
	return {
		all: {},
		'only root': { parentTagsAggregate: { count: { predicate: { _eq: 0 } } } },
	}[filterValue]
}

function generateActivityListItemSecondary({
	allEventsCount,
	allEventsDuration,
}: {
	allEventsCount: number
	allEventsDuration: number
}) {
	return `${allEventsCount} event${allEventsCount === 1 ? '' : 's'} ${
		allEventsDuration ? ` | ${generateDurationString(allEventsDuration)}` : ''
	}`
}

const test = gql`
	query Activities($where: ActivityBoolExp) {
		activities(orderBy: { allEventsCount: DESC }, where: $where) {
			id
			name
			color
			description
			allEventsCount
			allEventsDuration
			childTags {
				child {
					id
					name
					color
				}
			}
			parentTags {
				parent {
					id
				}
			}
		}
	}
`
