import { createContext, forwardRef, useContext, useMemo, useState } from 'react'

import { Box, Collapse, List, Typography } from '@mui/material'
import {
	ArrowDropDownRounded as ArrowDownIcon,
	ArrowRightRounded as ArrowRightIcon,
} from '@mui/icons-material'

const TreeViewContext = createContext<{
	tree: Map<number, { node; children: number[] }>
	isExpanded: (id: number) => boolean
	toggleIsExpanded: (id: number) => void
}>({
	tree: new Map(),
	isExpanded: id => false,
	toggleIsExpanded: id => null,
})

export default function TreeView({
	children,
	makeIsExpanded,
	tree,
	listProps,
}: {
	children: JSX.Element[]
	makeIsExpanded?: (expanded: number[]) => (id: number) => boolean
	tree: Map<number, { node; children: number[] }>
	listProps?: React.HTMLAttributes<HTMLUListElement>
}) {
	const { isExpanded, toggleIsExpanded } = useExpanded({ makeIsExpanded })

	return (
		<TreeViewContext.Provider value={{ tree, isExpanded, toggleIsExpanded }}>
			<List disablePadding {...listProps}>
				{children}
			</List>
		</TreeViewContext.Provider>
	)
}

export function TreeNode({
	nodeId,
	level = 0,
	rowContent,
	onRowClick,
	getRowProps,
	index = 0,
}: {
	nodeId: number
	level?: number
	rowContent: ({ id, name, color, allEventsCount, allEventsDuration, isParent }) => JSX.Element
	onRowClick?: (node) => void
	getRowProps?: (node, index: number) => React.HTMLAttributes<HTMLLIElement>
	index?: number
}) {
	const { tree, isExpanded: getIsExpanded, toggleIsExpanded } = useContext(TreeViewContext)

	const { node, children = [] } = tree.get(nodeId)!

	const isExpanded = getIsExpanded(nodeId)
	const isParent = node.parentTags?.length === 0

	return (
		<li {...getRowProps?.(node, index)}>
			<Box
				onClick={() => onRowClick?.(node)}
				sx={{
					display: 'flex',
					borderRadius: 3,
					cursor: 'pointer',
					'&:hover': { bgcolor: theme => theme.palette.action.hover },
				}}
			>
				<RowSpacing level={level} id={nodeId} />
				<ExpandButton
					hasChildren={children.length > 0}
					isExpanded={isExpanded}
					level={level}
					onClick={() => toggleIsExpanded(node.id)}
				/>
				<Box sx={{ py: 1, flex: 1 }}>
					{rowContent({
						id: node.id,
						name: node.name,
						color: node.color,
						allEventsCount: node.allEventsCount,
						allEventsDuration: node.allEventsDuration,
						isParent,
					})}
				</Box>
			</Box>
			<Collapse in={isExpanded} unmountOnExit>
				<List disablePadding>
					{children.map(nodeId => (
						<TreeNode
							key={nodeId}
							nodeId={nodeId}
							level={level + 1}
							rowContent={rowContent}
							onRowClick={onRowClick}
							getRowProps={getRowProps}
							index={index + 1}
						/>
					))}
				</List>
			</Collapse>
		</li>
	)
}

function RowSpacing({ level, id }: { level: number; id: number }) {
	return (
		<>
			{Array.from(Array(level).keys()).map(i => (
				<Box key={`${id}-${i}`} sx={{ width: 15, borderRight: 'solid #333 2px' }} />
			))}
		</>
	)
}

function ExpandButton({
	hasChildren,
	level,
	onClick,
	isExpanded,
}: {
	hasChildren: boolean
	level: number
	onClick: () => void
	isExpanded: boolean
}) {
	return (
		<Box sx={{ width: 28 }}>
			{hasChildren && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						height: 1,
						borderTopLeftRadius: level === 0 ? theme => theme.spacing(3) : 0,
						borderBottomLeftRadius: level === 0 ? theme => theme.spacing(3) : 0,
						'&:hover': { bgcolor: theme => theme.palette.action.hover },
					}}
					onClick={e => {
						e.stopPropagation()
						onClick()
					}}
				>
					{isExpanded ? (
						<ArrowDownIcon sx={{ color: '#555' }} />
					) : (
						<ArrowRightIcon sx={{ color: '#555' }} />
					)}
				</Box>
			)}
		</Box>
	)
}

function useSelected() {}

function useExpanded({
	expandAll = false,
	makeIsExpanded = expanded => id => expanded.includes(id),
}: {
	expandAll?: boolean
	makeIsExpanded?: (expanded: number[]) => (id: number) => boolean
}) {
	const [expanded, setExpanded] = useState<number[]>([])
	const isExpanded = makeIsExpanded(expanded)
	const toggleIsExpanded = (id: number) =>
		setExpanded(expanded =>
			isExpanded(id) ? expanded.filter(existing => existing !== id) : expanded.concat(id)
		)

	return {
		isExpanded,
		toggleIsExpanded,
	}
}
