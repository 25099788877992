import { useMutation, gql } from '@apollo/client'

export default function useUpdateActivity() {
	function updateActivity({ id, ...fields }) {
		updateActivityMutation({
			variables: {
				pkColumns: { id },
				_set: fields,
			},
		})
	}

	const [updateActivityMutation, updateActivityStatus] = useMutation(gql`
		mutation UpdateActivityByPk($pkColumns: ActivityPkColumnsInput!, $_set: ActivitySetInput!) {
			updateActivityByPk(pkColumns: $pkColumns, _set: $_set) {
				id
				name
				description
				color
			}
		}
	`)

	return {
		updateActivity,
		updateActivityStatus,
	}
}
