import { useMutation, gql } from '@apollo/client'
import { toISOStringWithTimezone } from 'utils'

export default function useLists() {
	const [insertListMutation] = useMutation(
		gql`
			mutation InsertList($object: ListInsertInput!) {
				insertListOne(object: $object) {
					id
					name
					description
				}
			}
		`,
		{
			refetchQueries: ['Lists'],
		}
	)

	const [updateListMutation] = useMutation(gql`
		mutation UpdateListByPk($pkColumns: ListPkColumnsInput!, $_set: ListSetInput!) {
			updateListByPk(pkColumns: $pkColumns, _set: $_set) {
				id
				name
				description
			}
		}
	`)

	const [deleteListMutation] = useMutation(
		gql`
			mutation DeleteListByPk($id: Int!) {
				deleteListByPk(id: $id) {
					id
				}
			}
		`,
		{
			update(
				cache,
				{
					data: {
						deleteListByPk: { id },
					},
				}
			) {
				cache.evict({ id: `List:${id}` })
			},
		}
	)

	function createList(list = {}) {
		insertListMutation({
			variables: { object: list },
		})
	}

	function updateList({ id, ...fields }) {
		updateListMutation({
			variables: {
				pkColumns: { id },
				_set: fields,
			},
		})
	}

	function deleteList(id) {
		deleteListMutation({
			variables: { id },
		})
	}

	return {
		createList,
		updateList,
		deleteList,
	}
}
