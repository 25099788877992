import { useState } from 'react'
import { DateTime } from 'luxon'

import useTagsState, { Tag } from './useTagsState'

export default function useEditEvent(originalEvent: Event) {
	const { tags, isTagSelected, selectTag, deselectTag, reset: resetTags } = useTagsState(
		originalEvent.tags
	)

	const [description, setDescription] = useState(originalEvent.description)
	const [startTime, setStartTime] = useState<DateTime>(DateTime.fromISO(originalEvent.startTime))
	const [endTime, setEndTime] = useState<DateTime | null>(
		originalEvent.endTime ? DateTime.fromISO(originalEvent.endTime) : null
	)

	function reset() {
		resetTags()
		setDescription(originalEvent.description)
		setStartTime(DateTime.fromISO(originalEvent.startTime))
		setEndTime(null)
	}

	return {
		event: {
			tags,
			description,
			duration: endTime?.diff(startTime, ['seconds']).seconds || null,
			startTime,
			endTime,
		},
		isTagSelected,
		selectTag,
		deselectTag,
		setDescription,
		setStartTime,
		setEndTime,
		reset,
	}
}

interface Event {
	startTime: string
	endTime: string | null
	duration: number | null
	description: string
	tags: Tag[]
}
