import { useRef } from 'react'

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAuth } from 'firebase/auth'

export default function ApolloProviderWithAuth({ children }: { children: React.ReactNode }) {
	const httpLink = createHttpLink({
		// uri: 'http://localhost:8080/v1/graphql',
		uri: 'https://glowing-primate-86.hasura.app/v1/graphql',
	})

	const authLink = setContext(async (_, { headers }) => {
		const token = await getAuth().currentUser?.getIdToken()

		return {
			headers: {
				...headers,
				Authorization: token ? `Bearer ${token}` : '',
			},
		}
	})

	const client = useRef(
		new ApolloClient({
			link: authLink.concat(httpLink),
			cache: new InMemoryCache({
				typePolicies: {
					Query: {
						fields: {
							events: {
								keyArgs: args => (args?.limit ? false : ['where', 'orderBy']),
								merge(existing = [], incoming) {
									return [...existing, ...incoming]
								},
							},
						},
					},
					Activity: {
						fields: {
							allEvents: {
								keyArgs: false,
								merge(existing = [], incoming, { args }) {
									return args?.limit ? [...existing, ...incoming] : incoming
								},
							},
						},
					},
				},
			}),
		})
	)

	return <ApolloProvider client={client.current}>{children}</ApolloProvider>
}
