import React from 'react'

import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'

export default function ({ children, to, ...rest }) {
	return (
		<Link to={to} {...rest}>
			<Typography variant={'link'} {...rest}>
				{children}
			</Typography>
		</Link>
	)
}
