import { useMutation, gql } from '@apollo/client'

export default function useAddTagsToEvent() {
	function addTagsToEvent(eventId: number, tagIds: number[]) {
		addTagsToEventMutation({
			variables: {
				activityEventPairs: tagIds.map(tagId => ({
					eventId: eventId,
					activityId: tagId,
				})),
			},
			update(
				cache,
				{
					data: {
						insertActivityEvent: { returning },
					},
				}
			) {
				for (const { activity, event } of returning) {
					cache.modify({
						id: cache.identify(activity),
						fields: {
							allEvents(existing, { toReference }) {
								// needs to be added in the right place, not just the top
								return [toReference(event), ...existing]
							},
						},
					})
				}
			},
		})
	}

	// perhaps this should be a 'insertEventOne' mutation so the event object
	// is not returned for every activity that  was added
	const [addTagsToEventMutation, addTagsToEventStatus] = useMutation(gql`
		mutation AddTagsToEvent($activityEventPairs: [ActivityEventInsertInput!]!) {
			insertActivityEvent(objects: $activityEventPairs) {
				returning {
					event {
						id
						tags {
							activity {
								id
							}
						}
					}
					activity {
						id
						allEventsDuration
						allEventsCount
					}
				}
			}
		}
	`)

	return {
		addTagsToEvent,
		addTagsToEventStatus,
	}
}
