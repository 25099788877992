import { Box, Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import TreeAutocomplete from './TreeAutocomplete'

export interface Tag {
	id: number
	name: string
	color: string | null
}

export default function TagArea({
	selectedTags,
	label = '',
	isTagSelected,
	onAddTag,
	onRemoveTag,
	isEditing,
}: {
	selectedTags: Tag[]
	label?: string
	isTagSelected: (id: number) => boolean
	onAddTag: (tag: Tag) => void
	onRemoveTag: (id: number) => void
	isEditing: boolean
}) {
	const navigate = useNavigate()

	return (
		<>
			{isEditing ? (
				<TreeAutocomplete
					label={label}
					tags={selectedTags}
					isTagSelected={isTagSelected}
					onAddTag={onAddTag}
					onRemoveTag={onRemoveTag}
				/>
			) : (
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						gap: 1,
					}}
				>
					{selectedTags.map(tag => (
						<Chip
							key={tag.id}
							label={tag.name}
							sx={{
								color: tag.color,
							}}
							onClick={() => navigate(`/activity/${tag.id}`)}
						/>
					))}
				</Box>
			)}
		</>
	)
}
