import { useMutation, gql } from '@apollo/client'

export default function useAddChildTagsToActivity() {
	function addChildTagsToActivity(parentTagId: number, childTagIds: number[]) {
		addTagsToActivityMutation({
			variables: {
				activityPairs: childTagIds.map(childTagId => ({
					childActivityId: childTagId,
					parentActivityId: parentTagId,
				})),
			},
		})
	}

	function addParentTagsToActivity(childTagId: number, parentTagIds: number[]) {
		addTagsToActivityMutation({
			variables: {
				activityPairs: parentTagIds.map(parentTagId => ({
					childActivityId: childTagId,
					parentActivityId: parentTagId,
				})),
			},
		})
	}

	const [addTagsToActivityMutation, addTagsToActivityStatus] = useMutation(gql`
		mutation AddTagsToTag($activityPairs: [ActivityActivityInsertInput!]!) {
			insertActivityActivity(objects: $activityPairs) {
				returning {
					child {
						id
						parentTags {
							parent {
								id
								name
								color
							}
						}
					}
					parent {
						id
						childTags {
							child {
								id
								name
								color
								allEventsCount
								allEventsDuration
							}
						}
					}
				}
			}
		}
	`)

	return {
		addChildTagsToActivity,
		addParentTagsToActivity,
		addTagsToActivityStatus,
	}
}
