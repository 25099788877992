import { useState } from 'react'

import { MenuItem } from '@mui/material'
import EditButton from './EditButton'
import Menu from './Menu'

export default function ActivityMenu({ setIsEditing, setIsDeleteDialogOpen }) {
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)

	return (
		<>
			<EditButton size="medium" onClick={e => setMenuAnchor(e.currentTarget)} />
			<Menu menuAnchor={menuAnchor} setMenuAnchor={setMenuAnchor}>
				<MenuItem
					onClick={() => {
						setIsEditing(true)
						setMenuAnchor(null)
					}}
				>
					Edit
				</MenuItem>
				<MenuItem
					onClick={() => {
						setIsDeleteDialogOpen(true)
						setMenuAnchor(null)
					}}
				>
					Delete
				</MenuItem>
			</Menu>
		</>
	)
}
