import { useState } from 'react'

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

import DeleteButton from './DeleteButton'

export default function DeleteConfirmationDialog({
	onClick,
	open,
	setOpen,
}: {
	onClick: () => void
	open: boolean
	setOpen: (open: boolean) => void
}) {
	return (
		<Dialog open={open} onClose={() => setOpen(false)} sx={{}}>
			<DialogTitle>Are you sure about that</DialogTitle>
			<DialogContent>Deleting this is irreversible</DialogContent>
			<DialogActions>
				<Button color="inherit" onClick={() => setOpen(false)}>
					cancel
				</Button>
				<Button color="error" onClick={onClick}>
					delete
				</Button>
			</DialogActions>
		</Dialog>
	)
}
