import padTo2Digits from './padTo2Digits'

export default function toISOStringWithTimezone(date = new Date()) {
	const tzOffset = -date.getTimezoneOffset()
	const diff = tzOffset >= 0 ? '+' : '-'
	const pad = n => padTo2Digits(Math.floor(Math.abs(n)))

	return (
		date.getFullYear() +
		'-' +
		pad(date.getMonth() + 1) +
		'-' +
		pad(date.getDate()) +
		'T' +
		pad(date.getHours()) +
		':' +
		pad(date.getMinutes()) +
		':' +
		pad(date.getSeconds()) +
		diff +
		pad(tzOffset / 60) +
		':' +
		pad(tzOffset % 60)
	)
}

console.log(toISOStringWithTimezone(new Date()))
