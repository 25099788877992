import { useState } from 'react'
import { useGoals } from 'hooks'
import { generateDurationString } from 'utils'

import { Box, Divider, InputAdornment, MenuItem, Select, Typography } from '@mui/material'

import DeleteButton from './DeleteButton'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import TextInput from './TextInput'

const PROGRESS_BAR_HEIGHT = 12
const PROGRESS_BAR_COLOR = '#007AFF'

export default function ActivityGoal({ goal, activity, isEditing = false }) {
	const { deleteGoal, updateGoal } = useGoals()

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

	let currentDuration: number = 0
	switch (goal.interval) {
		case 'week':
			currentDuration = activity.totalDurationWeek
			break
		case 'month':
			currentDuration = activity.totalDurationMonth
			break
		case 'year':
			currentDuration = activity.totalDurationYear
			break
	}

	const percentComplete = currentDuration / goal.duration

	return (
		<Box sx={{ display: 'flex', gap: 2, alignItems: 'center', p: 1 }}>
			<Box sx={{ flex: 1, textAlign: 'center' }}>
				<GoalProgressValue percentComplete={percentComplete} currentDuration={currentDuration} />
			</Box>
			<Box sx={{ flex: 3 }}>
				<GoalProgressBar percentComplete={percentComplete} />
			</Box>
			<Box sx={{ textAlign: 'center', flex: 1, display: 'flex', justifyContent: 'center' }}>
				<GoalMetric goal={goal} isEditing={isEditing} />
			</Box>
			{isEditing && <DeleteButton onClick={() => setIsDeleteDialogOpen(true)} />}
			<DeleteConfirmationDialog
				open={isDeleteDialogOpen}
				setOpen={setIsDeleteDialogOpen}
				onClick={() => deleteGoal(goal.id)}
			/>
		</Box>
	)
}

function GoalProgressValue({ percentComplete, currentDuration }) {
	return (
		<>
			<Typography variant="h5">{Math.round(percentComplete * 100 * 10) / 10}%</Typography>
			<Typography variant="caption">
				{generateDurationString(currentDuration, ['hours', 'minutes'])}
			</Typography>
		</>
	)
}

function GoalProgressBar({ percentComplete }: { percentComplete: number }) {
	return (
		<Box
			sx={{
				height: PROGRESS_BAR_HEIGHT,
				borderRadius: 3,
				overflow: 'hidden',
				backgroundColor: theme => theme.palette.background.default,
			}}
		>
			<Box
				sx={{
					width: Math.min(1, percentComplete),
					height: 1,
					backgroundColor: PROGRESS_BAR_COLOR,
					borderRadius: 3,
					transition: 'all 1s ease',
				}}
			/>
		</Box>
	)
}

function GoalMetric({ isEditing, goal }) {
	const { updateGoal } = useGoals()
	return (
		<Box>
			{isEditing ? (
				<TextInput
					value={goal.duration / 60 / 60}
					type="number"
					onChange={value => updateGoal({ id: goal.id, duration: +value * 60 * 60 })}
					inputProps={{
						endAdornment: <InputAdornment position="end">hours</InputAdornment>,
					}}
				/>
			) : (
				<Typography>{generateDurationString(goal.duration)}</Typography>
			)}
			<Divider color="white" />
			{isEditing ? (
				<Select
					value={goal.interval}
					onChange={e => updateGoal({ id: goal.id, interval: e.target.value })}
				>
					<MenuItem value={'week'}>week</MenuItem>
					<MenuItem value={'month'}>month</MenuItem>
					<MenuItem value={'year'}>year</MenuItem>
				</Select>
			) : (
				<Typography>{goal.interval}</Typography>
			)}
		</Box>
	)
}
