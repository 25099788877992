import { useQuery, gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useLists } from 'hooks'
import { DateTime } from 'luxon'

import { Button, List } from '@mui/material'
import { DeleteButton, ListItem, Loading } from 'components'

export default function ListsPage() {
	const { loading, data: { lists = [] } = {} } = useQuery(GET_LISTS)
	const { createList, deleteList } = useLists()
	const navigate = useNavigate()

	return (
		<>
			<Button onClick={() => createList()}>New List</Button>
			<Loading loading={loading}>
				<List>
					{lists.map((list, i) => (
						<ListItem
							primary={list.name || 'unnamed list'}
							secondary={DateTime.fromISO(list.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
							onClick={() => navigate(`/list/${list.id}`)}
							secondaryAction={<DeleteButton onClick={() => deleteList(list.id)} />}
							key={i}
						/>
					))}
				</List>
			</Loading>
		</>
	)
}

const GET_LISTS = gql`
	query Lists {
		lists(orderBy: { id: ASC }) {
			id
			name
			description
			createdAt
			updatedAt
		}
	}
`
