import React, { useContext } from 'react'
import { Theme } from 'context'
import { useBoop } from 'hooks'

import { useNavigate } from 'react-router-dom'

import { animated } from '@react-spring/web'

import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'

import { useAuthState } from 'react-firebase-hooks/auth'

import { Stack, Button, IconButton } from '@mui/material'
import { KronosIcon, Link, Toggle } from 'components'
import { Brightness4 as Brightness4Icon, Brightness7 as Brightness7Icon } from '@mui/icons-material'
import NavBar from './NavBar'

const signIn = () => {
	const provider = new GoogleAuthProvider()
	const auth = getAuth()
	signInWithPopup(auth, provider)
		.then(async result => {
			// This gives you a Google Access Token. You can use it to access the Google API.
			const credential = GoogleAuthProvider.credentialFromResult(result)
			// The signed-in user info.
			const user = result.user as any

			// wait 5 seconds for the hasura claims to be added
			await new Promise(r => setTimeout(r, 5000))

			const tokenResult = await user.getIdTokenResult(true)
			console.log(tokenResult)
			if (tokenResult.claims['https://hasura.io/jwt/claims']) {
				const [firstName, lastName] = user.displayName.split(' ')
				console.log(result)
				fetch('https://glowing-primate-86.hasura.app/v1/graphql', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${user.accessToken}`,
					},
					body: JSON.stringify({
						query: `
							mutation InsertUser {
								insertUserOne(object: { email: "${user.email}", firstName: "${firstName}", lastName: "${lastName}"} ) {
									id
								}
							}
						`,
					}),
				})
					.then(response => response.json())
					.then(data => console.log(data))
					.catch(error => console.error(error))
			} else {
				console.log('failed to find hasura claims on jwt')
			}
		})
		.catch(error => {
			// Handle Errors here.
			const errorCode = error.code
			const errorMessage = error.message
			// The email of the user's account used.
			const email = error.email
			// The AuthCredential type that was used.
			const credential = GoogleAuthProvider.credentialFromError(error)
			alert(errorMessage)
		})
}
export default function AppBar() {
	const auth = getAuth()
	const navigate = useNavigate()

	const [user, loading, error] = useAuthState(auth)

	const { mode, toggleMode } = useContext(Theme.Context)
	const { style, trigger } = useBoop({ rotation: 15 })

	return (
		<Stack
			direction={'row'}
			sx={{
				alignItems: 'center',
				justifyContent: 'space-between',
				py: 1,
				position: 'sticky',
				top: 0,
				backgroundColor: theme => theme.palette.background.default,
				zIndex: 2,
			}}
		>
			<Link to="/">
				<KronosIcon fontSize="large" color="red" />
			</Link>
			<NavBar />
			{user ? (
				<Button color="inherit" onClick={() => signOut(auth)}>
					Logout
				</Button>
			) : (
				<Button color="inherit" onClick={() => signIn()}>
					Login
				</Button>
			)}
			<IconButton onClick={toggleMode} onMouseEnter={trigger}>
				<Toggle
					toggle={mode === 'light'}
					A={
						<animated.div style={style}>
							<Brightness7Icon />
						</animated.div>
					}
					B={
						<animated.div style={style}>
							<Brightness4Icon />
						</animated.div>
					}
				/>
			</IconButton>
		</Stack>
	)
}
