import { IconButton } from '@mui/material'
import { PlayArrowRounded as PlayArrowIcon, StopRounded as StopIcon } from '@mui/icons-material'

export default function StartStopButton({
	condition,
	onStartClick,
	onStopClick,
	size = 'small',
	disabled = false,
}: {
	condition: boolean
	onStartClick: () => void
	onStopClick: () => void
	size?: 'small' | 'large'
	disabled?: boolean
}) {
	return condition ? (
		<IconButton disabled={disabled} size="small" onClick={onStopClick}>
			<StopIcon fontSize={size} />
		</IconButton>
	) : (
		<IconButton size="small" disabled={disabled} onClick={onStartClick}>
			<PlayArrowIcon fontSize={size} />
		</IconButton>
	)
}
