import React from 'react'

import { Typography, Stack, Divider } from '@mui/material'
import { Link } from 'components'

export default function NavBar() {
	return (
		<Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
			{/* <Link to={'events'}>Events</Link> */}
			<Link to={'day-view'}>Timeline</Link>
			<Link to={'goals'}>Goals</Link>
			<Link to={'activities'}>Activities</Link>
			<Link to={'lists'}>Lists</Link>
			{/* <Link to={'pomodoro'}>Pomodoro</Link> */}
		</Stack>
	)
}
