import { IconButton } from '@mui/material'
import { RemoveRounded as RemoveIcon } from '@mui/icons-material'

export default function DeleteButton({
	onClick,
	disabled = false,
}: {
	onClick: () => void
	disabled?: boolean
}) {
	return (
		<IconButton size="small" disabled={disabled} onClick={onClick}>
			<RemoveIcon color={'error'} fontSize="inherit" />
		</IconButton>
	)
}
