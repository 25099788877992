export default async function getCoordinateString() {
	const position = await getCoordinates()
	if (position) {
		return `(${position.coords.latitude},${position.coords.longitude})`
	}

	return null
}

export function getCoordinates() {
	if (navigator.geolocation) {
		return new Promise<GeolocationPosition>((resolve, reject) =>
			navigator.geolocation.getCurrentPosition(resolve, reject)
		)
	}
	return null
}
